import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../../components/Dashboard/NotFound/NotFound";
import LandingPageView from "../../components/_NEW//LandingPage/sections/view/LandingPageView";
import LoadingScreen from "../../components/_NEW/LandingPage/loadingScreen/LoadingScreen";
import PublicService from "../../service/PublicService";
import OldView from "../../components/_OLD/LandingPage/view/LandingPageViewOld";
import { checkIsOldPage } from "../../utils/checkIsOldPage";

export default function Affiliate() {
  const { user_id } = useParams();
  const navigate = useNavigate();

  /**
   * Validations
   *
   * states = "unchecked", true, false
   */
  const isValidType = [
    "finance",
    "stormgas",
    "property",
    "individual",
    "basic",
  ];
  const [landingPageIdIsValid, setLandingPageIdIsValid] = useState("unchecked"); // unchecked, true , false

  /**
   * Data
   */
  const [landingPageData, setLandingPageData] = useState(null);
  const [localReviews, setLocalReviews] = useState(null);
  const [promoter, setPromoter] = useState(null);
  const [user, setUser] = useState(null);
  const staticCheck = isValidType.includes(landingPageData?.theme);
  const isLoading =
    landingPageIdIsValid === "unchecked" ||
    landingPageData === null ||
    user === null ||
    localReviews === null ||
    promoter === null;

  const [isOldPage, setIsOldPage] = useState(true);

  const getPromoterAndSet = useCallback(async () => {
    if (!user_id) return;
    const userRes = await PublicService.getUserName(user_id);
    setUser(userRes.data);
    setPromoter({
      firstname: `${userRes?.data?.firstname}`,
      lastname: `${userRes?.data?.lastname}`,
    });
  }, [user_id]);

  /**
   * Landingpage check
   */
  useEffect(() => {
    // guards

    // promotion link not checked
    PublicService.getAffiliatePage()
      .then(async ({ data }) => {
        if (!data?.landingPage) return setLandingPageIdIsValid(false);

        setLandingPageIdIsValid(true);
        setLandingPageData(data.landingPage);
        setLocalReviews(data.localReviews);

        const relevantKeys = [
          "accompanyingTextOne",
          "introVideoHeader",
          "introductoryText",
          "accompanyingTextTwo",
          "accompanyingTextThree",
          "questionaireIntroductionText",
        ];

        const checkedNewPage = relevantKeys.some(
          (key) =>
            data.landingPage[key] && checkIsOldPage(data.landingPage[key])
        );

        if (checkedNewPage) {
          setIsOldPage(false);
        }
      })
      .catch((_) => {
        setLandingPageIdIsValid(false);
      });
  }, []);

  /**
   * On Success
   */
  useEffect(() => {
    getPromoterAndSet();
  }, [getPromoterAndSet]);

  /**
   * Link nicht richtig
   */
  if (!staticCheck) {
    return <NotFound />;
  }

  /**
   * Link is not valid
   */
  if (!landingPageIdIsValid) {
    navigate(
      "/notfound?note=Dieser Link ist leider nicht mehr gültig. Bitte fordere beim Absender einen neuen an."
    );
  }

  /**
   * Loading...
   */
  if (isLoading) {
    return <LoadingScreen />;
  } else {
    if (!isOldPage) {
      return (
        <LandingPageView
          user={user}
          landingPageData={landingPageData}
          promoter={promoter}
          localReviews={localReviews}
          type={landingPageData?.theme}
          landingPageId={landingPageData?._id}
          promoter_id={user_id}
          affiliate={true}
        />
      );
    } else {
      return (
        <OldView
          user={user}
          landingPageData={landingPageData}
          promoter={promoter}
          localReviews={localReviews}
          type={landingPageData?.theme}
        />
      );
    }
  }
}
