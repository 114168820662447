import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PublicService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/public`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  getPrivacy() {
    return this.api.get("/getPrivacy");
  }
  getCMS() {
    return this.api.get(`/getCMS`);
  }

  getTerms() {
    return this.api.get("/getTerms");
  }
  getPromotionLink(id) {
    return this.api.get(`/getPromotionLink?id=${id}`);
  }

  getImpressum() {
    return this.api.get("/getImpressum");
  }
  getTestimonials() {
    return this.api.get("/getTestimonials");
  }

  createEmpfehlung(empfehlungData) {
    return this.api.post("/createEmpfehlung", empfehlungData);
  }

  requestSupport(supportTicketData) {
    return this.api.post("/request_support", supportTicketData);
  }

  subscribeNewsletter(subscriberData) {
    return this.api.post("/subscribe_newsletter", subscriberData);
  }

  cancelNewsletter(subscriberData) {
    return this.api.delete("/cancel_newsletter", { data: subscriberData });
  }

  getLandingpage(id) {
    return this.api.get("/getLandingpage", { params: { id } });
  }
  getAffiliatePage() {
    return this.api.get("/getAffiliatePage");
  }

  confirmDemoMeeting(email) {
    return this.api.post("/confirmDemoMeeting", { email });
  }

  checkDemoMeeting(email) {
    return this.api.post("/checkDemoMeeting", { email });
  }

  erlaubnisPromoter(id) {
    return this.api.post(`/erlaubnisPromoter?id=${id}`, {
      baseUrl: window.location.origin,
    });
  }

  getSubscriptionInfo() {
    return this.api.get("/getSubscriptionInfo");
  }

  getTutorials() {
    return this.api.get("/getTutorials");
  }
  detailTutorial(id) {
    return this.api.get(`/detailTutorial?id=${id}`);
  }
  getPromoterInitials(id) {
    return this.api.get(`/getPromoterInitials?promoter_id=${id}`);
  }
  getQuestions(landingpageId) {
    return this.api.get("/getQuestions", {
      params: { landingpage_id: landingpageId },
    });
  }
  getGoogleReviews(landingpageId) {
    return this.api.get("/getGoogleReviews", {
      params: { landingpage_id: landingpageId },
    });
  }
  getLocalReviews(landingpageId) {
    return this.api.get("/getLocalReviews", {
      params: { landingpage_id: landingpageId },
    });
  }
  getAdminConfigure() {
    return this.api.get("/getAdminConfigure");
  }
  getUserName(id) {
    return this.api.get(`/getUserName?userId=${id}`);
  }
  getUserNameOfPromoter(id) {
    return this.api.get(`/getUserName?promoterId=${id}`);
  }
  getPartnerBonuses(promoter_id) {
    return this.api.get(`/getPartnerBonuses?promoter_id=${promoter_id}`);
  }
}

export default new PublicService();
