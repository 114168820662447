import React from "react";

const BasicButtonConfig = ({
  text = "Default Button",
  backgroundColor = "#FFD814",
  textColor = "black",
  onClick,
  disabled = false,
  className = "",
}) => {
  return (
    <div className="flex justify-center items-center">
      <button
        onClick={onClick}
        disabled={disabled}
        className={`py-2 px-4 rounded font-semibold ${className} w-[16rem] h-[3.2rem]`}
        style={{
          background: backgroundColor || "#FFD814",
          color: textColor,
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled ? 0.6 : 1,
        }}
      >
        {text}
      </button>
    </div>
  );
};

export default BasicButtonConfig;
