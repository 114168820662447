import { Button, Card } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Link } from "react-router-dom";
import { useMessageContext } from "../../../../context/messageContext";
import { urlRegex } from "../../../../utils/validation";
import Inputs from "../../shared/Input";
import Box from "../Layout/Box";
import SwitchTitle from "../shared/SwitchTitle";

const CalendlyConfig = forwardRef(
  (
    { landingPageData: { calendlyLink, activateCalendly }, disabled, onChange },
    ref
  ) => {
    const { showMessage } = useMessageContext();
    const [calendlyLinkState, setCalendlyLinkState] = useState(calendlyLink);
    const [activateCalendlyState, setActivateCalendlyState] =
      useState(activateCalendly);

    useImperativeHandle(ref, () => ({
      async save() {
        if (disabled) return;

        console.log("CalendlyConfig.save()");

        const deactivate =
          !urlRegex.test(calendlyLinkState) && activateCalendlyState;

        if (deactivate) {
          showMessage(
            "error",
            "Kalenderlink deaktiviert: Bitte gültigen Link eingeben. (https://)"
          );
          setActivateCalendlyState(false);
        }

        return {
          landingPageData: {
            calendlyLink: calendlyLinkState,
            activateCalendly: deactivate ? false : activateCalendlyState,
          },
        };
      },
    }));

    // Handler zum Aktualisieren von activateCalendly
    const handleActivateCalendlyChange = (value) => {
      setActivateCalendlyState(value);
      onChange?.(value); 
    };

    return (
      <Box className="z-1">
        <SwitchTitle
          checked={disabled ? false : activateCalendlyState}
          onChange={handleActivateCalendlyChange}
        >
          Kalender-Link
        </SwitchTitle>

        {disabled ? (
          <div className="h-[200px] relative">
            <div
              style={{ zIndex: 99999999999999999 }}
              className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
            />
            <Card
              style={{ zIndex: 99999999999999999 }}
              className="absolute top-0 left-1/2 transform -translate-x-1/2 p-2 text-center"
            >
              <h2 className="text-2xl font-bold mb-4">Upgrade benötigt</h2>
              <p className="mb-4">
                Um auf diesen Bereich zugreifen zu können, musst du auf eine
                höhere Abonnementstufe upgraden.
              </p>
              <Link to="/dashboard/upgrade">
                <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                  Upgrade
                </Button>
              </Link>
            </Card>
          </div>
        ) : (
          <>
            <Inputs
              value={calendlyLinkState}
              onChange={(e) => setCalendlyLinkState(e.target.value)}
              label={"Kalender-Link"}
              emptyCheck={activateCalendlyState}
            />
          </>
        )}
      </Box>
    );
  }
);

export default CalendlyConfig;
