import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Box from "../components/_NEW/LandingPageConfig/Layout/Box";
import Header from "../components/_NEW/LandingPageConfig/Layout/Header";
import CalendlyConfig from "../components/_NEW/LandingPageConfig/Sections/CalendlyConfig";
import ClosingVideoConfig from "../components/_NEW/LandingPageConfig/Sections/ClosingVideoConfig";
import HeroConfig from "../components/_NEW/LandingPageConfig/Sections/HeroConfig";
import IntroductionToClient from "../components/_NEW/LandingPageConfig/Sections/IntroductionToClient";
import LocalReviews from "../components/_NEW/LandingPageConfig/Sections/LocalReviews";
import NameConfig from "../components/_NEW/LandingPageConfig/Sections/NameConfig";
import Pramien from "../components/_NEW/LandingPageConfig/Sections/Pramien";
import Questions from "../components/_NEW/LandingPageConfig/Sections/Questions";
import Rechtliches from "../components/_NEW/LandingPageConfig/Sections/Rechtliches";
import TextConfig from "../components/_NEW/LandingPageConfig/Sections/TextConfig";
import BegleitTextConfig from "../components/_NEW/LandingPageConfig/Sections/BegleitTextConfig";
import WhatsAppConfig from "../components/_NEW/LandingPageConfig/Sections/WhatsAppConfig";
import Button from "../components/_NEW/LandingPageConfig/shared/Button";
import IconButton from "../components/_NEW/LandingPageConfig/shared/IconButton";
import { useMessageContext } from "../context/messageContext";
import { setLoading } from "../redux/auth/actions";
import { selectUser } from "../redux/auth/selectors";
import { store } from "../redux/store";
import LandingPageService from "../service/LandingPageService";
import localReviewService from "../service/LocalReviewService";
import QuestionService from "../service/QuestionService";
import UploadToCloudService from "../service/UploadToCloudService";
import { aboTarif } from "../utils/divers";
import { extractBase64Images } from "../utils/extractBase64Images";
import { handleUnusedImages } from "../utils/handleUnusedImages";
import { compressImageToMaxSize } from "../utils/helperImageCompression";
import { replaceBase64WithCloudinaryUrls } from "../utils/replaceBase64WithCloudinaryUrls";
import ButtonConfig from "../components/_NEW/LandingPageConfig/Sections/ButtonConfig";

const LandingPageConfig = () => {
  const { id } = useParams();
  const { type, id: ladingpageId } = useParams();
  const navigate = useNavigate();
  const { showMessage, clearMessage } = useMessageContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [landingPageData, setLandingPageData] = useState(null);
  const [localReviewsData, setLocalReviewsData] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [waState, setWaState] = useState(false);
  const [caState, setCaState] = useState(false);

  useEffect(() => {
    if (landingPageData) {
      setWaState(landingPageData.activateWhatsApp || false);
      setCaState(landingPageData.activateCalendly || false);
    }
  }, [landingPageData]);

  // Sections
  const begleittextRef = useRef(null);
  const buttonRef = useRef(null);
  const heroConfigRef = useRef(null);
  const localReviewsRef = useRef(null);
  const introductionToClient = useRef(null);
  const textConfigRef = useRef(null);
  const questionsRef = useRef(null);
  const closingVideoRef = useRef(null);
  const rechtliches = useRef(null);
  const pramienRef = useRef(null);
  const whatsAppConfigRef = useRef(null);
  const calendlyConfigRef = useRef(null);
  const nameConfig = useRef(null);
  const { user } = useSelector(selectUser);

  const saveAll = async () => {
    console.log("saveAll() gestartet.");

    // Ladezustand setzen
    setIsSaving(true);
    store.dispatch(setLoading(true));
    showMessage("loading", "Speichern", 0, "lp-config-loading");

    try {
      console.log("Speichervorgang beginnt...");

      let promiseArray = [];
      if (landingPageData.theme !== "basic") {
        promiseArray = [
          heroConfigRef.current.save(),
          localReviewsRef.current.save(),
          introductionToClient.current.save(),
          textConfigRef.current.save(),
          questionsRef.current.save(),
          closingVideoRef.current.save(),
          rechtliches.current.save(),
          pramienRef.current.save(),
          whatsAppConfigRef.current.save(),
          calendlyConfigRef.current.save(),
          nameConfig.current.save(),
          begleittextRef.current.save(),
          buttonRef.current.save(),
        ];
      } else {
        if (!landingPageData.activateBasicQuestion) {
          promiseArray = [
            heroConfigRef.current.save(),
            textConfigRef.current.save(),
            whatsAppConfigRef.current.save(),
            introductionToClient.current.save(),
            nameConfig.current.save(),
            rechtliches.current.save(),
            begleittextRef.current.save(),
            buttonRef.current.save(),
          ];
        } else {
          promiseArray = [
            heroConfigRef.current.save(),
            textConfigRef.current.save(),
            nameConfig.current.save(),
            whatsAppConfigRef.current.save(),
            questionsRef.current.save(),
            rechtliches.current.save(),
            begleittextRef.current.save(),
            buttonRef.current.save(),
          ];
        }
      }
      const allNewValues = await Promise.all(promiseArray);
      console.log("Alle Komponenten wurden gespeichert.", allNewValues);

      const landingPagePayload = allNewValues.reduce(
        (accumulator, currentObject) => {
          return {
            ...accumulator,
            ...(currentObject?.landingPageData || {}),
          };
        },
        {}
      );
      console.log("LandingPage-Payload erstellt:", landingPagePayload);

      const localReviewsPayload = allNewValues.reduce(
        (accumulator, currentObject) => {
          return [...accumulator, ...(currentObject?.localReviewsData || [])];
        },
        []
      );
      console.log("LocalReviews-Payload erstellt:", localReviewsPayload);

      const questionsPayload = allNewValues.reduce(
        (accumulator, currentObject) => {
          return [...accumulator, ...(currentObject?.questionData || [])];
        },
        []
      );
      console.log("Questions-Payload erstellt:", questionsPayload);

      const editorFields = [
        "introductoryText",
        "accompanyingTextOne",
        "accompanyingTextTwo",
        "accompanyingTextThree",
        "introVideoHeader",
        "questionaireIntroductionText",
      ];

      let allUpdatedContents = [];
      let updatedImageList = [...(landingPageData.imageList || [])];
      const maxSizeInBytes = 500 * 1024;
      console.log("Aktuelle Bildliste:", updatedImageList);

      for (const field of editorFields) {
        const editorContent = landingPagePayload[field];
        if (editorContent) {
          console.log(`Verarbeite Editor-Feld: ${field}`);

          const base64Images = extractBase64Images(editorContent);
          console.log(`Gefundene Base64-Bilder in ${field}:`, base64Images);

          const newBase64Images = base64Images.filter(
            (base64Image) =>
              !updatedImageList.some((url) => url.includes(base64Image))
          );
          console.log(`Neue Base64-Bilder zum Hochladen:`, newBase64Images);

          if (newBase64Images.length > 0) {
            console.log("Bilder werden komprimiert...");
            const compressedImages = await Promise.all(
              newBase64Images.map(async (base64Image, index) => {
                try {
                  const compressedImage = await compressImageToMaxSize(
                    base64Image,
                    maxSizeInBytes
                  );
                  console.log(
                    `Bild ${index + 1} erfolgreich komprimiert.`,
                    compressedImage
                  );
                  return compressedImage;
                } catch (error) {
                  console.error(
                    `Fehler beim Komprimieren von Bild ${index + 1}:`,
                    error
                  );
                  throw error;
                }
              })
            );

            console.log("Bilder werden zu Cloudinary hochgeladen...");
            let uploadedImages;
            try {
              uploadedImages = await UploadToCloudService.uploadBase64Image(
                compressedImages
              );
              console.log("Bilder erfolgreich hochgeladen:", uploadedImages);
            } catch (error) {
              console.error("Fehler beim Hochladen der Bilder:", error);
              if (error.response) {
                console.error(
                  `Upload-Fehler (Status ${error.response.status}):`,
                  error.response.data
                );
                if (error.response.status === 429) {
                  console.error("Rate Limit erreicht.");
                }
              }
              throw error;
            }

            if (!Array.isArray(uploadedImages)) {
              uploadedImages = [uploadedImages];
            }

            const imageMap = newBase64Images.map((base64Image, index) => ({
              base64Image,
              url: uploadedImages[index].secure_url,
            }));
            console.log("Image Map erstellt:", imageMap);

            const newImageUrls = uploadedImages.map((img) => img.secure_url);
            updatedImageList = [...updatedImageList, ...newImageUrls];
            console.log("Aktualisierte Bildliste:", updatedImageList);

            const updatedEditorContent = replaceBase64WithCloudinaryUrls(
              editorContent,
              imageMap
            );
            console.log(
              `Editor-Inhalt für Feld ${field} aktualisiert.`,
              updatedEditorContent
            );

            landingPagePayload[field] = updatedEditorContent;
            allUpdatedContents.push(updatedEditorContent);
          } else {
            console.log(`Keine neuen Bilder zum Hochladen für Feld ${field}.`);
            allUpdatedContents.push(editorContent);
          }
        } else {
          console.log(`Kein Inhalt für Feld ${field} vorhanden.`);
        }
      }

      console.log("Überprüfe auf ungenutzte Bilder...");
      const finalImageList = await handleUnusedImages(
        ladingpageId,
        allUpdatedContents
      );
      console.log("Finale Bildliste:", finalImageList);

      landingPagePayload.imageList = finalImageList;

      console.log("Sende Daten an den Server...");
      await Promise.all([
        LandingPageService.update(ladingpageId, landingPagePayload),
        localReviewService.wipeAndAdd(ladingpageId, localReviewsPayload),
        QuestionService.wipeAndAdd(ladingpageId, questionsPayload),
      ]);
      console.log("Daten erfolgreich gespeichert.");

      clearMessage("lp-config-loading");
      showMessage("success", "Erfolgreich gespeichert!");
    } catch (error) {
      console.error("Fehler beim Speichern:", error);
      if (error.response) {
        console.error(
          `Serverantwort (Status ${error.response.status}):`,
          error.response.data
        );
        if (error.response.status === 429) {
          console.error("Rate Limit Fehler erkannt.");
          showMessage(
            "error",
            "Etwas ist schiefgelaufen. Rate Limit erreicht, bitte versuchen Sie es später erneut."
          );
        } else {
          showMessage(
            "error",
            `Etwas ist schiefgelaufen. Serverfehler (${error.response.status}).`
          );
        }
      } else {
        showMessage("error", `Etwas ist schiefgelaufen: ${error.message}`);
      }
      clearMessage("lp-config-loading");
    } finally {
      setIsSaving(false);
      store.dispatch(setLoading(false));
      console.log("saveAll() beendet.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          landingPageResponse,
          localReviewsResponse,
          questionDataResponse,
        ] = await Promise.all([
          LandingPageService.detail(ladingpageId),
          localReviewService.list(ladingpageId),
          QuestionService.listQuestions(ladingpageId),
        ]);

        setLandingPageData(landingPageResponse.data);
        setLocalReviewsData(localReviewsResponse.data);
        setQuestionData(questionDataResponse.data);
      } catch (error) {
        showMessage(
          "error",
          "Ups, da ist etwas schief gegangen. Versuche es später noch mal"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const test = async () => {
  //     const moin = await LandingPageService.detail("677b0d172baec21fabdcf750");
  //     // "677e41b351bdd78abe1ef2cd" "6776964f89756c395b019f65" "677bdb79a5d648ef46c49f69"
  //     console.log(moin);
  //     const moin2 = await QuestionService.listQuestions(
  //       "677ebddee662ecc3a9def53a"
  //     );
  //     console.log(moin2);
  //   };
  //   test();
  //   console.log();
  // }, []);

  return (
    <div className="max-w-[2000px] w-screen">
      {/** Border */}
      <div className="tertiary:w-[70%] sm:w-[85%] w-[95%] m-auto">
        {/** Header */}
        <Header
          className={"pt-10 pb-14"}
          onArrowClick={() => navigate("/dashboard/landingpage")}
          onButtonClick={() =>
            window.open(`/landingpage/${type}/${id}/viewmode`, "_blank")
          }
        />

        {/**Sections */}
        {isLoading && (
          <div className="m-auto w-min">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                  }}
                  spin
                />
              }
            />
          </div>
        )}
        {!isLoading ? (
          landingPageData?.theme !== "basic" ? (
            <div className="flex flex-col gap-10">
              <HeroConfig
                landingPageData={landingPageData}
                ref={heroConfigRef}
                type={type}
              />
              <LocalReviews
                ref={localReviewsRef}
                landingPageData={landingPageData}
                localReviewsData={localReviewsData}
              />
              <IntroductionToClient
                landingPageData={landingPageData}
                ref={introductionToClient}
              />
              <TextConfig
                landingPageData={landingPageData}
                ref={textConfigRef}
              />
              <Box className="text-center text-[#868686] text-[1.25rem]">
                Erstelle interessante und themenbezogene Fragen ...
              </Box>
              <Box className="text-center text-[#868686] text-[1.25rem]">
                Die rot umrahmte Frage ist dabei immer die Ablehnungsantwort.
              </Box>
              <Questions questionData={questionData} ref={questionsRef} />
              <Rechtliches
                landingPageData={landingPageData}
                ref={rechtliches}
              />
              <Pramien landingPageData={landingPageData} ref={pramienRef} />
              <ClosingVideoConfig
                landingPageData={landingPageData}
                ref={closingVideoRef}
                disabled={aboTarif(user) < 2}
              />
              <WhatsAppConfig
                landingPageData={landingPageData}
                ref={whatsAppConfigRef}
                disabled={aboTarif(user) < 2}
                onSwitchChange={(value) => setWaState(value)}
              />
              <ButtonConfig
                landingPageData={landingPageData}
                disabled={aboTarif(user) < 2}
                ref={buttonRef}
                wa={waState}
                ca={caState}
              />
              <CalendlyConfig
                landingPageData={landingPageData}
                ref={calendlyConfigRef}
                disabled={aboTarif(user) < 2}
                onChange={(value) => setCaState(value)}
              />
              <BegleitTextConfig
                landingPageData={landingPageData}
                ref={begleittextRef}
              />
              <NameConfig landingPageData={landingPageData} ref={nameConfig} />
            </div>
          ) : (
            <div className="flex flex-col gap-10">
              <HeroConfig
                landingPageData={landingPageData}
                ref={heroConfigRef}
                type={type}
              />
              {!landingPageData.activateBasicQuestion && (
                <>
                  {" "}
                  <IntroductionToClient
                    landingPageData={landingPageData}
                    ref={introductionToClient}
                  />
                  <WhatsAppConfig
                    landingPageData={landingPageData}
                    ref={whatsAppConfigRef}
                    disabled={aboTarif(user) < 2}
                  />
                  <ButtonConfig
                    landingPageData={landingPageData}
                    disabled={aboTarif(user) < 2}
                    ref={buttonRef}
                    wa={waState}
                    ca={caState}
                  />
                </>
              )}
              <TextConfig
                landingPageData={landingPageData}
                ref={textConfigRef}
              />
              {landingPageData.activateBasicQuestion && (
                <>
                  <Box className="text-center text-[#868686] text-[1.25rem]">
                    Erstelle interessante und themenbezogene Fragen ...
                  </Box>
                  <Box className="text-center text-[#868686] text-[1.25rem]">
                    Die rot umrahmte Frage ist dabei immer die
                    Ablehnungsantwort.
                  </Box>
                  <Questions questionData={questionData} ref={questionsRef} />
                  <WhatsAppConfig
                    landingPageData={landingPageData}
                    ref={whatsAppConfigRef}
                    disabled={aboTarif(user) < 2}
                    onSwitchChange={(value) => setWaState(value)}
                  />
                  <ButtonConfig
                    landingPageData={landingPageData}
                    disabled={aboTarif(user) < 2}
                    ref={buttonRef}
                    wa={waState}
                    ca={caState}
                  />
                </>
              )}
              <BegleitTextConfig
                landingPageData={landingPageData}
                ref={begleittextRef}
              />
              <NameConfig landingPageData={landingPageData} ref={nameConfig} />
              <Rechtliches
                landingPageData={landingPageData}
                ref={rechtliches}
              />
            </div>
          )
        ) : null}

        {/**Button Desktop */}

        <Button
          text="Speichern"
          onClick={saveAll}
          className="hidden my-10 primary:block"
          disabled={isSaving || isLoading}
          loading={isSaving}
        />

        {/**Button Mobile */}
        <div className="fixed bottom-0 left-0 right-0 z-10 py-3 bg-white shadow-bottom-bar primary:hidden">
          <Button
            text="Speichern"
            onClick={saveAll}
            className="tertiary:w-[70%] sm:w-[85%] w-[95%] m-auto"
            disabled={isSaving || isLoading}
            loading={isSaving}
          />
        </div>

        <div className="bottom-0 left-0 right-0 z-[-1] py-3 primary:hidden opacity-0">
          <Button text="A" onClick={() => {}} disabled={true} />
        </div>

        <IconButton
          onClick={saveAll}
          className="hidden primary:fixed primary:block bottom-10 right-2 tertiary:right-10"
          disabled={isSaving || isLoading}
        />
      </div>
    </div>
  );
};

export default LandingPageConfig;
