import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../service/AdminService";
import UserService from "../../../service/UserService";
function DebounceSelect({
  usesimple,
  fetchOptions,
  debounceTimeout = 800,
  className,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return !usesimple ? (
    <div className="flex items-center border border-grey-accent rounded-[10px] px-2 py-1 relative">
      <AiOutlineSearch className="text-responsiveHeading" />
      <Select
        labelInValue
        filterOption={false}
        showSearch
        suffixIcon={<></>}
        className="min-w-[200px] w-full"
        placeholder="Suchbegriff eingeben..."
        onSearch={debounceFetcher}
        {...props}
        options={options}
      />
    </div>
  ) : (
    <Select
      labelInValue
      filterOption={false}
      showSearch
      suffixIcon={<></>}
      placeholder="Suchbegriff eingeben..."
      onSearch={debounceFetcher}
      {...props}
      options={options}
    />
  );
}

async function fetchUserList(username) {
  const user = await AdminService.userSearch({
    text: username,
  });
  const ans = [
    ...user.data.users.map((e) => ({
      value: e._id,
      label: e?.firstname + " " + e?.lastname,
      key: e._id + "promoter",
    })),
    ...user.data.leads.map((e) => ({
      value: e._id + "_lead",
      label: e?.firstname + " " + e?.lastname,
      key: e._id + "promoter",
      lead: true,
    })),
  ];
  return ans;
}
const SearchSelect = ({
  usesimple = false,
  value,
  setValue,
  prefixCls,
  style,
}) => {
  const navigate = useNavigate();
  return (
    <DebounceSelect
      value={value}
      placeholder="Suchbegriff eingeben..."
      fetchOptions={fetchUserList}
      prefixCls={prefixCls}
      usesimple={usesimple}
      onSelect={(newValue) => {
        const navigateDetails = {
          realKey: newValue?.value?.replace?.("_lead", ""),
          route: newValue?.value?.includes?.("_lead") ? "leads" : "partner",
        };

        navigate(
          `/admindashboard/${navigateDetails.route}?${navigateDetails.route}=${navigateDetails.realKey}`
        );

        setValue(newValue);
      }}
      style={
        style || {
          width: "100%",
        }
      }
    />
  );
};
export default SearchSelect;
