// src/KontoEditPage.js
import {
  Button,
  Checkbox,
  message,
  Modal,
  Skeleton,
  Switch,
  Tooltip,
} from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { login } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import SubscriptionService from "../../service/SubscriptionService";
import UploadToCloudService from "../../service/UploadToCloudService";
import { default as UserService } from "../../service/UserService";
import { LogoUploadRow } from "../_NEW/LandingPageConfig/Sections/HeroConfig";
import { PopUpComponent } from "../ConfirmPopup";
import { default as CollapseItem } from "../Dashboard/kampaign/CollapseItem";
import { AddLandingPageModal } from "../Einstellungen/AddLandingPageModal";
import { fields } from "../Einstellungen/Data";
import { Heading } from "../Einstellungen/Heading";
import HTMLTextEditor from "../Einstellungen/HtmlEditor";
import { default as FloatLabel } from "../FloatingLabel";
import useWindowWidth from "../Home/MakeYouFit/useWindowWidth";
import SubscriptionComponent from "./SubscriptionComponent";

const editors = {
  // defaultPrivacyText: "Datenschutz-Text",
  // defaultPrivacyLink: "Datenschutz-Link",
  defaultImpressumText: "Impressum-Text",
  defaultImpressumLink: "Impressum-Link",
};

const KontoEditPage = ({ title }) => {
  const [userobj, setUserObj] = useState(null);
  const { user: savedUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState();
  const navigate = useNavigate();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [kundigungModal, setKundigungModal] = useState(false);
  const [popupComponent, setPopupComponent] = useState(false);
  const [formOneLoading, setFormOneLoading] = useState({
    userdata: false,
    address: false,
    email: false,
    password: false,
    rechtliches: false,
  });
  const logoRef = useRef(null);

  const [checkboxTarifWechselValue, setCheckboxTarifWechselValue] =
    useState(false);
  useEffect(() => {
    if (!popupComponent) setCheckboxTarifWechselValue(false);
  }, [popupComponent]);

  const width = useWindowWidth();

  React.useEffect(() => {
    const getSubscriptionInfo = async () => {
      const { data } = await SubscriptionService.getSubscriptionInfo();
      setSubscription(data?.subscription);
    };
    getSubscriptionInfo();
  }, []);

  const [password, setPassword] = useState({
    password: {
      show: false,
      text: "",
    },
    confirmpassword: {
      show: false,
      text: "",
    },
  });
  const [newMail, setNewMail] = useState({});
  const [avatar, setAvatar] = useState("");
  const [user, setUser] = useState({});
  const proofEmail = () => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newMail.newmail || !newMail.newmailconfirm) {
      message.error("Bitte fülle beide Felder aus");
      return false;
    }
    if (newMail.newmail !== newMail.newmailconfirm) {
      message.error("Email Adressen stimmen nicht überein");
      return false;
    }

    if (!validRegex.test(newMail.newmail)) {
      message.error("Geben Sie eine gültige E-Mail-Adresse ein");
      return false;
    }
    return true;
  };

  const handleNewEmailChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setNewMail((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const submitEmailChange = async () => {
    setFormOneLoading({ email: true });
    try {
      await UserService.updateEmail({
        email: newMail?.newmail,
        baseUrl: window.origin,
        oldMail: user?.email,
      });
    } catch (error) {
      console.log(error);
    }
    setFormOneLoading({ email: false });
  };

  // Neu Laden nach Email Änderung
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "emailVerified" && event.newValue === "true") {
        window.location.reload();
      }
      localStorage.removeItem("emailVerified");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const { data } = await UserService.me();
        setUserObj(data?.user);
        setUser((prev) => ({ ...prev, ...data?.user }));
        store.dispatch(login(data));

        title("Konto");
        setTimeout(() => title("Konto"), 200);
      } catch (error) {}
    };
    getUserObj();
  }, [title]);

  const handlePasswordUIChange = (key, subkey, value) => {
    setPassword((prev) => ({
      ...prev,
      [key]: { ...prev[key], [subkey]: value },
    }));
  };

  const onFinish = async (e, formType) => {
    setFormOneLoading({ [formType]: true });
    e?.preventDefault?.();
    const finalUser = { ...user };
    try {
      await UserService.updateUserInfo({ ...finalUser });
      const { data: me } = await UserService.me();
      store.dispatch(login(me));
      message.success("Aktualisiert");
    } catch (error) {
      console.log(error);
    }
    setFormOneLoading({ [formType]: false });
  };

  const handleChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setUser({
      ...user,
      [key]: value,
    });
    setUserObj({
      ...user,
      [key]: value,
    });
  };

  const handleConfirmCancel = useCallback(async () => {
    if (!checkboxValue) return message.info("Bitte kreuze das Kästchen an");

    setUserObj((u) => ({ ...u, autoRenewals: false }));
    message.success("Automatische Verlängerung wurde ausgeschaltet");

    await UserService.updateUserInfo({ autoRenewals: false });
  }, [checkboxValue]);

  if (!userobj) {
    return <Skeleton active />;
  }

  return (
    <div>
      <div className="flex justify-center flex-wrap special-upload">
        <LogoUploadRow
          uploadRef={logoRef}
          text="Lade hier Dein Avatar hoch"
          url={avatar || user?.avatar || "/images/postausgang-btn.png"}
          setUrl={async (newUrl) => {
            setLoading(true);
            try {
              setAvatar(newUrl);
              let url = "";
              if (!newUrl?.includes?.("https://") && newUrl) {
                const fileFromSession = await fetch(newUrl);
                const blob = await fileFromSession.blob();

                const res = await UploadToCloudService.upload(blob, "image");
                const { secure_url } = res;
                url = secure_url;
              }

              if (url) {
                setAvatar(url);
                const finalUser = { ...user };
                finalUser.avatar = url;
                await UserService.updateUserInfo({ ...finalUser });
                const { data: me } = await UserService.me();
                setUser(me.user);
                store.dispatch(login(me));
              }
            } catch (e) {
            } finally {
              setLoading(false);
            }
          }}
          textSrcNotThere="Avatar hochladen"
          textSrcThere="Avatar ändern"
          imageUploadId="upload-avatar-landingpageconfig-first"
          emptyCheck={false}
          center
        />
      </div>

      <div className="my-8">
        <CollapseItem
          spaceOben
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Persönliche Informationen
            </div>
          }
        >
          <form onSubmit={(e) => onFinish(e, "userdata")}>
            <div className="my-5 flex flex-col gap-8 w-full">
              <div className="flex gap-8 flex-wrap ">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Vorname"}
                >
                  <div className="flex gap-1 items-center">
                    <input
                      type="text"
                      name="firstname"
                      required={savedUser["firstname"]?.length > 0}
                      onChange={handleChange}
                      value={user?.firstname}
                      className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow"
                      disabled
                    />
                    <Tooltip title="Dein Name kann nur auf Anfrage mit Einreichung von Personalien geändert werden. Wende Dich dazu bitte an den Support.">
                      <AiOutlineInfoCircle fontSize={width < 550 ? 14 : 20} />
                    </Tooltip>
                  </div>
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Nachname"}
                >
                  <div className="flex gap-1 items-center">
                    <input
                      type="text"
                      name="lastname"
                      required={savedUser["lastname"]?.length > 0}
                      onChange={handleChange}
                      value={user?.lastname}
                      className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow"
                      disabled
                    />
                    <Tooltip title="Dein Name kann nur auf Anfrage mit Einreichung von Personalien geändert werden. Wende Dich dazu bitte an den Support.">
                      <AiOutlineInfoCircle fontSize={width < 550 ? 14 : 20} />
                    </Tooltip>
                  </div>
                </FloatLabel>
              </div>

              <div className="flex gap-8 flex-wrap flex-grow">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Firma"}
                >
                  <input
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    required={savedUser["companyName"]?.length > 0}
                    value={user?.companyName}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Umsatzsteuernummer / Steuernummer"}
                >
                  <input
                    type="text"
                    name="VAT"
                    required={savedUser["VAT"]?.length > 0}
                    onChange={handleChange}
                    value={user?.VAT}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
              </div>
              <div className="flex gap-8 flex-wrap flex-grow">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Straße"}
                >
                  <input
                    type="text"
                    name="street"
                    onChange={handleChange}
                    required={savedUser["street"]?.length > 0}
                    value={user?.street}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Hausnummer"}
                >
                  <input
                    name="streetNr"
                    onChange={handleChange}
                    required={savedUser["streetNr"]?.length > 0}
                    value={user?.streetNr}
                    className="border-[1px] w-[100px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto   bg-transparent"
                  />
                </FloatLabel>
              </div>
              <div className="flex gap-8 flex-wrap">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Ort"}
                >
                  <input
                    type="text"
                    name="city"
                    required={savedUser["city"]?.length > 0}
                    onChange={handleChange}
                    value={user?.city}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"PLZ"}
                >
                  <input
                    type="number"
                    name="postCode"
                    required={savedUser["postCode"]?.length > 0}
                    onChange={handleChange}
                    value={user?.postCode}
                    className="border-[1px] w-[100px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto   bg-transparent"
                  />
                </FloatLabel>
              </div>
              <div>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold"
                  className={"flex-grow  "}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Geburtsdatum"}
                >
                  <div className="flex gap-1 items-center">
                    <input
                      type="date"
                      name="dateOfBirth"
                      required={savedUser["dateOfBirth"]?.length > 0}
                      disabled={savedUser["dateOfBirth"]?.length > 0}
                      onChange={handleChange}
                      value={user?.dateOfBirth?.split?.("T")[0]}
                      className="border-[1px]  border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px]    bg-transparent"
                    />
                    {savedUser["dateOfBirth"]?.length > 0 && (
                      <Tooltip title="Dein Geburtsdatum kann nur auf Anfrage mit Einreichung von Personalien geändert werden. Wende Dich dazu bitte an den Support.">
                        <AiOutlineInfoCircle fontSize={width < 550 ? 14 : 20} />
                      </Tooltip>
                    )}
                  </div>
                </FloatLabel>
              </div>

              <div className="my-10 w-full ">
                <FloatLabel
                  label={"Branche auswählen"}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={" flex-grow"}
                  keepitfocused
                >
                  <ReactSelect
                    className="border-[1px] border-[#d8d8d8] font-semibold !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                    options={fields}
                    classNamePrefix={"profile-select"}
                    value={{
                      value: userobj?.industry,
                      label: userobj?.industry,
                    }}
                    required={savedUser["industry"]?.length > 0}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          value: e.value,
                          name: "industry",
                        },
                      });
                      onFinish();
                    }}
                    components={{ IndicatorSeparator: "" }}
                  ></ReactSelect>
                </FloatLabel>
              </div>

              <div className="flex gap-8 flex-wrap">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"E-Mail Adresse "}
                >
                  <input
                    type="text"
                    name="email"
                    required={savedUser["email"]?.length > 0}
                    readOnly
                    value={user?.email}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Telefonnummer"}
                >
                  <input
                    type="text"
                    name="phone"
                    required={savedUser["phone"]?.length > 0}
                    onChange={handleChange}
                    value={user?.phone}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
              </div>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
              loading={formOneLoading.userdata}
            >
              Speichern
            </Button>
          </form>
        </CollapseItem>
      </div>

      <div className="my-8">
        <CollapseItem
          spaceOben
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Alternative Rechnungsadresse
            </div>
          }
        >
          <form onSubmit={(e) => onFinish(e, "address")} className="py-2">
            <div className="trkisswitch font-bold text-md md:text-lg">
              Gleiche wie oben{" "}
              <Switch
                checked={user?.invoiceAddressSame}
                onChange={(e) => {
                  setUser((prev) => ({ ...prev, invoiceAddressSame: e }));
                }}
              />
            </div>

            {!user?.invoiceAddressSame && (
              <div className="my-5 flex flex-col gap-8 w-full">
                <div className="flex gap-8 flex-wrap">
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"Straße"}
                  >
                    <input
                      type="text"
                      name="invoiceStreet"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoiceStreet}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"Hausnummer"}
                  >
                    <input
                      type="text"
                      name="invoiceStreetNr"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoiceStreetNr}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                </div>

                <div className="flex gap-8 flex-wrap flex-grow">
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"Ort "}
                  >
                    <input
                      type="text"
                      name="invoicePostCode"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoicePostCode}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"PLZ"}
                  >
                    <input
                      type="text"
                      name="invoiceCity"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoiceCity}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                </div>
              </div>
            )}

            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
              loading={formOneLoading.address}
            >
              Speichern
            </Button>
          </form>
        </CollapseItem>
      </div>
      <div>
        <CollapseItem
          spaceOben
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Email Adresse ändern
            </div>
          }
        >
          <form method="POST" className="my-5 flex flex-col gap-8 w-full">
            <div className="flex gap-8 flex-wrap ">
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"neue Email"}
              >
                <input
                  required
                  type={"email"}
                  value={newMail?.newmail}
                  name="newmail"
                  onChange={handleNewEmailChange}
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
              </FloatLabel>
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"Erneut neue Email"}
              >
                <input
                  required
                  type={"email"}
                  value={newMail?.newmailconfirm}
                  name="newmailconfirm"
                  onChange={handleNewEmailChange}
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
              </FloatLabel>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
              loading={formOneLoading.email}
              onClick={(e) => {
                e.preventDefault();
                if (proofEmail()) {
                  return PopUpComponent({
                    onConfirm: submitEmailChange,
                    heading: "Änderung Email Adresse",
                    subheading:
                      "Mit dieser Änderung wird deine Email Adresse vollständig ersetzt! Die neue Email Adresse wird mit der Änderung ab sofort sowohl für die Anmeldung bei EinGuterTipp, sowie jeglichen Schriftwechsel, als auch rund um das Thema Zahlungen und Abrechnungen genutzt.",
                    confirmButtonText: "Bestätigen",
                  });
                }
              }}
            >
              Ändern
            </Button>
          </form>
        </CollapseItem>
      </div>
      <div>
        <CollapseItem
          spaceOben
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Passwort ändern
            </div>
          }
        >
          <form method="POST" className="my-5 flex flex-col gap-8 w-full">
            <div className="flex gap-8 flex-wrap ">
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"neues Passwort"}
              >
                <input
                  required
                  type={password.password.show ? "text" : "password"}
                  value={password.password.text}
                  name="password"
                  onChange={(e) =>
                    handlePasswordUIChange("password", "text", e.target.value)
                  }
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
                {password.password.show ? (
                  <AiOutlineEyeInvisible
                    onClick={() =>
                      handlePasswordUIChange(
                        "password",
                        "show",
                        !password.password.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() =>
                      handlePasswordUIChange(
                        "password",
                        "show",
                        !password.password.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                )}
              </FloatLabel>
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"altes Passwort eingeben"}
              >
                <input
                  required
                  type={password.confirmpassword.show ? "text" : "password"}
                  value={password.confirmpassword.text}
                  name="confirmpassword"
                  onChange={(e) =>
                    handlePasswordUIChange(
                      "confirmpassword",
                      "text",
                      e.target.value
                    )
                  }
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
                {password.confirmpassword.show ? (
                  <AiOutlineEyeInvisible
                    onClick={() =>
                      handlePasswordUIChange(
                        "confirmpassword",
                        "show",
                        !password.confirmpassword.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() =>
                      handlePasswordUIChange(
                        "confirmpassword",
                        "show",
                        !password.confirmpassword.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                )}
              </FloatLabel>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
              loading={formOneLoading.password}
              onClick={(e) => {
                setFormOneLoading({ password: true });
                e.preventDefault();
                UserService.changePassword({
                  newPassword: password.password.text,
                  oldPassword: password.confirmpassword.text,
                });
                setFormOneLoading({ password: false });
              }}
            >
              Ändern
            </Button>
          </form>
        </CollapseItem>
      </div>

      <CollapseItem
        spaceOben
        title={
          <div className="text-md md:text-xl font-semibold">Rechtliches</div>
        }
        defaultOpen={true}
      >
        <div className="p-8 flex flex-col gap-8">
          {Object.keys(editors).map((e, i) => {
            const isEditor = editors[e].split("-")[1] === "Text";
            return (
              <div className="flex flex-col gap-8">
                {!isEditor ? (
                  <>
                    <FloatLabel
                      label={editors[e]}
                      unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                      className={"xl:flex-grow-0 flex-grow"}
                      focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
                      keepitfocused
                      key={i}
                    >
                      <input
                        type="text"
                        className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                        name={e}
                        value={userobj[e]}
                        onChange={handleChange}
                      />
                    </FloatLabel>
                    {e === "defaultImpressumLink" && (
                      <div className="mb-5">
                        <a
                          href="https://www.e-recht24.de/impressum-generator.html"
                          target="_blank"
                        >
                          Wenn Du noch kein Impressum hast, kannst du es mit
                          eRecht24 problemlos selbst erstellen.
                        </a>
                      </div>
                    )}
                    {e === "defaultPrivacyLink" && (
                      <div className="mb-5">
                        <a
                          href="https://www.e-recht24.de/muster-datenschutzerklaerung.html"
                          target="_blank"
                        >
                          Wenn Du noch kein Datenschutz hast, kannst du es mit
                          eRecht24 problemlos selbst erstellen.
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <HTMLTextEditor
                      label={editors[e]}
                      value={userobj[e]}
                      onChange={(text) => {
                        handleChange({
                          target: {
                            name: e,
                            value: text,
                          },
                        });
                      }}
                      key={i}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Button
          type="primary"
          htmlType="submit"
          onClick={(e) => onFinish(e, "rechtliches")}
          loading={formOneLoading.rechtliches}
          className="h-[60px] text-md md:text-xl font-semibold w-full mb-5"
        >
          Speichern
        </Button>
      </CollapseItem>

      {subscription?.id ? (
        <SubscriptionComponent user={userobj} subscription={subscription} />
      ) : (
        <div
          className="text-white p-5 rounded-11xl shadow-md mt-10"
          style={{ background: "#0084F7" }}
        >
          <div className="mt-4">
            <button
              className="bg-transparent hover:bg-blue-500 text-white border border-white hover:text-white px-4 py-2 rounded-full w-full"
              onClick={async () => {
                navigate("/dashboard/upgrade");
              }}
            >
              Jetzt Abonnieren
            </button>
          </div>
        </div>
      )}

      <Modal
        open={kundigungModal}
        onCancel={() => setKundigungModal(false)}
        title="Achtung!"
        footer={[]}
      >
        <div>
          <p className="my-1">
            Du bist dabei deine Lizenz zum Ablauf der aktuellen Laufzeitperiode
            zu kündigen. Bitte bedenke, dass Dir und deinen Kunden ab dann keine
            Funktionen mehr zur Verfügung stehen. Das bedeutet unter Anderem
            z.B.: auch keinen Zugriff mehr auf Daten wie Promoter oder
            Empfehlungen. Wir empfehlen dir für weitere Informationen und
            Optionen erstmal den Support an zu schreiben.
          </p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige, dass ich auf weitere Infos verzichte und mir der
              Folgen bewusst bin
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              setKundigungModal(false);
              await handleConfirmCancel();
            }}
          >
            Abonnement kündigen
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={(e) => {
              setKundigungModal(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>

      <Modal
        open={popupComponent}
        onCancel={() => setPopupComponent(false)}
        title={popupComponent?.heading}
        footer={[]}
      >
        <div>
          <p className="my-1">{popupComponent?.subheading}</p>
          {popupComponent?.checkbox && (
            <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
              <Checkbox
                checked={checkboxTarifWechselValue}
                onChange={(e) => setCheckboxTarifWechselValue(e.target.checked)}
              />
              <p className="text-ellipsis text-start hyphens-auto ">
                {popupComponent?.checkbox}
              </p>
            </div>
          )}
        </div>
        {!popupComponent?.hideButtons && (
          <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
            <Button
              className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
              onClick={async () => {
                if (!checkboxTarifWechselValue)
                  return message.info("Bitte kreuze das Kästchen an");

                if (popupComponent?.selection < userobj.renewalInterval) {
                  return navigate("/dashboard/support");
                }

                setPopupComponent(false);
                setUser({
                  ...user,
                  renewalInterval: popupComponent?.selection,
                });

                UserService.updateUserInfo({
                  renewalInterval: popupComponent?.selection,
                }).then(async () => {
                  const res = await UserService.me();
                  setUserObj(res?.data?.user);

                  const res2 = await SubscriptionService.getSubscriptionInfo();
                  setSubscription(res2?.data?.subscription);
                });
              }}
            >
              {popupComponent?.selection < userobj.renewalInterval
                ? "Zum Support"
                : "Bestätigen"}
            </Button>
            <Button
              type="primary"
              className="h-[46px] w-[250px] rounded-[10px]"
              onClick={(e) => {
                setPopupComponent(false);
              }}
            >
              Abbrechen
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default KontoEditPage;
