import AdminService from "../../../service/AdminService";
import EmpfehlungenService from "../../../service/EmpfehlungService";
function CardObj(
  data,
  getLists,
  expanded,
  list,
  color,
  setTopValue,
  setexpanded,
  topValue,
  setList,
  i
) {
  return {
    data,
    setList: async ({ key, value, istKontaktGesprach, anrede, email }) => {
      try {
        if (key === "notes") {
          await AdminService.interessentUpdateNotes(data._id, {
            notes: value,
          });
          await getLists();
          return;
        } else if (
          key === "status" &&
          value === "neuer-kunde-der-promoter-wurde"
        ) {
          // Info: Hier wird "Kunden zum Promoter machen" gemacht
          await getLists();
          return;
        } else if (key === "status") {
          const query = {
            ...data,
            [key]: value,
          };
          if (typeof istKontaktGesprach === "boolean")
            query.istKontaktGesprach = istKontaktGesprach;
          await AdminService.interessentUpdate(data._id, query);
          await getLists();
          return;
        }

        setList((prev) =>
          [...prev].map((e) => {
            if (e._id !== data._id) {
              return e;
            } else {
              return { ...e, [key]: value };
            }
          })
        );
      } catch (error) {}
    },
    deleteLead: async () => {
      try {
        await AdminService.interessentDelete(data._id);
        setexpanded(null);
        await getLists();
      } catch (error) {}
    },
    expanded: expanded === i,
    className: "absolute transition-all ",
    manipulateTop: setTopValue,
    bg: {
      neu: ["#EFEFEF ", "#BDC6BE ", "#BDC6BE "],
      terminiert: ["#A8FBFC ", "#6DB5DB", "#2239A8 "],
      kunde: ["#E4FDE5", "#81E985 ", "#81E985"],
      "kein-interesse": ["#FDA69F", "#E3725F", "#D05835"],
      "laufender-vorgang": ["#FFE465", "#F3F189", "#F3F450"],
      "neuer-kunde-der-promoter-wurde": ["#DF9AEB", "#DF9AEB", "#DF9AEB"],
    },
    paddingBottom:
      i ===
      list.filter((e) => {
        if (
          color["kunde"] === false &&
          color["laufender-vorgang"] === false &&
          color["neu"] === false &&
          color["kein-interesse"] === false &&
          color["terminiert"] === false &&
          color["neuer-kunde-der-promoter-wurde"] === false
        ) {
          return e;
        }
        return color[e.status];
      }).length -
        1
        ? 50
        : 200,
    top: topValue,
    index: i,
    props: {
      style: {
        zIndex: i * 1,
        top: expanded === null || i <= expanded ? i * 100 : i * 100 + topValue,
      },
      onClick: (callback) => {
        if (expanded !== i) {
          setTopValue(280);
          setexpanded(i);
          if (callback) callback();
        }
      },
    },
  };
}
export { CardObj };
