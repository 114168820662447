import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class UserService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/user`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  me() {
    return this.api.post("/me", {
      baseUrl: window.location.origin,
    });
  }

  updateUserInfo(userInfo) {
    return this.api.put("/updateInfo", userInfo);
  }

  searchUsers(searchText, promotercard) {
    return this.api.post("/search", { text: searchText, promotercard });
  }
  searchEmpfehlungUberPromoter(searchText) {
    return this.api.post("/searchEmpfehlungUberPromoter", { text: searchText });
  }

  /**
   * timeFilter: 7days lastmonth 3months lastyear alltime
   */
  getDashboardStats(timeFilter) {
    return this.api.get(`/getDashboardStats?timeFilter=${timeFilter}`);
  }

  /**
   * timeFilter: 7days lastmonth 3months lastyear alltime
   */
  getLeistungsFormelStats(timeFilter) {
    return this.api.get(`/leistungsFormel?timeFilter=${timeFilter}`);
  }

  /**
   * timeFilter: 7days lastmonth 3months lastyear alltime
   */
  getLeaderboard(timeFilter) {
    return this.api.get(`/getLeaderboard?timeFilter=${timeFilter}`);
  }

  getMonthlyStatsForYear() {
    return this.api.get("/getMonthlyStatsForYear");
  }

  subscribeToPlan(subscriptionData) {
    return this.api.post("/subscribe", {
      ...subscriptionData,
      baseUrl: window.location.origin,
    });
  }

  getSubscription() {
    return this.api.get("/getSubscription");
  }
  changePassword({ newPassword, oldPassword }) {
    return this.api.post("/changePassword", { newPassword, oldPassword });
  }

  cancelSubscription() {
    return this.api.delete("/cancelSubscription");
  }
  updateEmail({ email, baseUrl, oldMail }) {
    return this.api.post("/updateEmail", { email, baseUrl, oldMail });
  }
  updateVerifiedEmail({ token }) {
    return this.api.post("/updateVerifiedEmail", { token });
  }
  bonusClaim({ token, bonus }) {
    return this.api.post(`/bonusClaim?token=${token}`, { bonus });
  }
  getClaims(id) {
    return this.api.get(`/getClaims?id=${id}`);
  }
}

export default new UserService();
