import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ isDashboard = false }) => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-left sm:justify-between font-semibold text-[10px] sm:text-[16px]  gap-5 sm:gap-20 my-5 w-full px-0 sm:px-5">
      <h1 className="flex-1 whitespace-wrap order-2 sm:order-1">
        © Copyright EinGuterTipp
      </h1>
      <div className="flex flex-1 items-center gap-2 sm:gap-10 jutsify-center sm:justify-end flex-wrap sm:flex-nowrap order-1 sm:order-2">
        <Link to="/impressum">
          <span>Impressum</span>
        </Link>
        {isDashboard && <Link to={"/konditionen"}>Konditionen</Link>}
        <Link to={"/datenschutz"}>Datenschutz</Link>
        <Link to={"/agb"}>Nutzungsbedingungen</Link>
      </div>
    </div>
  );
};

export default Footer;
