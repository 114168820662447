import React from "react";
import FinanceView from "./FinanceView";
import PropertyView from "./PropertyView";
import StromGasView from "./StromGasView";
import BasicView from "./BasicView";

const View = (props) => {
  const { type } = props;

  if (type === "finance" || type === "individual") {
    return <FinanceView {...props} />;
  }

  if (type === "property") {
    return <PropertyView {...props} />;
  }

  if (type === "stormgas") {
    return <StromGasView {...props} />;
  }

  if (type === "basic") {
    return <BasicView {...props} />;
  }

  return null;
};

View.defaultProps = {
  affiliate: false,
};

export default View;
