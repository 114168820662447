import {
  BankOutlined,
  BulbOutlined,
  CloseOutlined,
  DollarOutlined,
  GlobalOutlined,
  HomeOutlined,
  PlusOutlined,
  SafetyOutlined,
  SolutionOutlined,
  ThunderboltOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button as AntdButton,
  Input,
  Modal,
  Popconfirm,
  Select,
  Upload,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import CustomThemeService from "../../service/CustomThemeService";
import ImageUploadService from "../../service/UploadToCloudService";

import { DeleteIcon } from "lucide-react";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/auth/selectors";
import CrudService from "../../service/CrudService";
import LandingPageService from "../../service/LandingPageService";
import FloatLabel from "../FloatingLabel";
import {
  Button,
  FloatingLabelInput,
  Heading,
  Img,
  SelectBox,
} from "./components";

const { Option } = Select;

const defaultThemes = [
  { value: "immobilien", label: "Immobilien", icon: "/images/home-2.svg" },
  {
    value: "finanzberater",
    label: "Finanzberater",
    icon: "/images/coins-2.svg",
  },
  {
    value: "finanzierung",
    label: "Kredite & Darlehen",
    icon: "/images/finanzierung.png",
  },
  { value: "sparen", label: "Sparen", icon: "/images/piggy-bank-2.svg" },
  { value: "strom", label: "Strom & Gas", icon: "/images/bolt-2.svg" },
  {
    value: "versicherung",
    label: "Versicherung",
    icon: "/images/shield-check-3.svg",
  },
  {
    value: "photovoltaik",
    label: "Photovoltaik",
    icon: "/images/solar-panel-2.svg",
  },
  {
    value: "kapitalanlage",
    label: "Kapitalanlage",
    icon: "/images/growth-chart-invest 1.svg",
  },
  {
    value: "schuldnerberatung",
    label: "Schuldnerberatung",
    icon: "/images/bomb.svg",
  },
];

export function CreateThemeModal({ open, onOpenChange, onThemeCreate }) {
  const [themeName, setThemeName] = useState("");
  const [icon, setIcon] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await CustomThemeService.createCustomTheme({
        name: themeName,
        icon,
      });
      onThemeCreate(response.data);
      onOpenChange(false);
      setThemeName("");
      setIcon("");
    } catch (error) {
      message.error("Failed to create custom theme");
    }
  };

  return (
    <Modal
      wrapClassName="standardRoot-modal"
      footer={[]}
      open={open}
      onCancel={() => onOpenChange(false)}
      closeIcon={<></>}
    >
      <div className="ml-1 flex items-center justify-between gap-5 md:ml-0">
        <Heading
          size="desktop_label_bold"
          as="h1"
          className="self-end text-[20px] font-semibold text-[#000000]"
        >
          Neues Thema Erstellen
        </Heading>
        <img
          src="/images/img_cross_small_1.svg"
          alt="Close Icon"
          className="h-[32px] cursor-pointer"
          onClick={() => onOpenChange(false)}
        />
      </div>

      <div className="flex flex-col gap-6 py-4">
        <div className="space-y-2">
          <label className="text-sm text-gray-500">Thema Name</label>
          <Input
            value={themeName}
            onChange={(e) => setThemeName(e.target.value)}
            placeholder="Handwerker"
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm text-gray-500">Icon</label>
          <div className=" rounded-lg ">
            <Upload.Dragger
              name="file"
              customRequest={async ({ file, onSuccess }) => {
                try {
                  const { secure_url } = await ImageUploadService.upload(file);
                  setIcon(secure_url);
                  onSuccess();
                } catch (err) {
                  message.error("Fehlgeschlagen");
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">Icon hochladen</p>
            </Upload.Dragger>
            {icon && (
              <div className="w-full flex justify-center">
                <img
                  src={icon}
                  className="w-[75px] h-[75px] object-contain mt-4"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Button
        type="primary"
        className="w-full ml-1 mt-[60px] rounded-[10px] px-[34px] md:ml-0 sm:px-5"
        onClick={handleSubmit}
      >
        Jetzt hinzufügen
      </Button>
    </Modal>
  );
}

export function AddLandingPageModal({
  open,
  onOpenChange,
  handleRefreshPages,
  editingLP,
  setEditingLP,
  landingPageThemes,
  isEGTBonus,
}) {
  const [landingPage, setLandingPage] = useState("");
  const [theme, setTheme] = useState("");
  const [description, setDescription] = useState("");
  const [createThemeModalOpen, setCreateThemeModalOpen] = useState(false);
  const [customThemes, setCustomThemes] = useState([]);
  const [landingPages, setLandingPages] = useState([]);

  const { user } = useSelector(selectUser);

  useEffect(() => {
    LandingPageService.list().then((res) => {
      setLandingPages(res.data);
    });
  }, []);

  const fetchCustomThemes = async () => {
    try {
      const response = await CustomThemeService.getCustomThemes();
      setCustomThemes(
        response.data.map((a) => ({
          ...a,
          value: a._id,
          isCustom: true,
        }))
      );
    } catch (error) {
      message.error("Failed to fetch custom themes");
    }
  };
  useEffect(() => {
    fetchCustomThemes();
  }, []);

  useEffect(() => {
    if (editingLP) {
      setLandingPage(editingLP?.landingPage?._id);
      setTheme(editingLP.theme);
      setDescription(editingLP.description);
    } else {
      setLandingPage("");
      setTheme("");
      setDescription("");
    }
  }, [editingLP]);

  const handleSubmit = useCallback(() => {
    if (!landingPage) return message.error("Bitte Landingpage auswählen");
    if (!theme) return message.error("Bitte Thema auswählen");
    if (editingLP) {
      CrudService.update("LinkKonfig", editingLP._id, {
        [isEGTBonus ? "isEGTBonus" : "user_id"]: isEGTBonus ? true : user?._id,
        landingPage,
        theme,
        description,
      }).then(() => handleRefreshPages());
    } else {
      CrudService.create("LinkKonfig", {
        [isEGTBonus ? "isEGTBonus" : "user_id"]: isEGTBonus ? true : user?._id,
        landingPage,
        theme,
        description,
        disabled: !!isEGTBonus,
      }).then(() => handleRefreshPages());
    }

    setEditingLP(null);
    onOpenChange(false);
    setLandingPage("");
    setTheme("");
    setDescription("");
  }, [editingLP, user, landingPage, theme, description, isEGTBonus]);

  const handleThemeCreate = (newTheme) => {
    setCustomThemes([...customThemes, { ...newTheme, isCustom: true }]);
  };

  return (
    <>
      <Modal
        wrapClassName="standardRoot-modal"
        closeIcon={<></>}
        onCancel={() => onOpenChange(false)}
        open={open}
        footer={[]}
      >
        {/* recommendation portal section */}
        <div className="flex w-full  justify-center rounded-[10px] bg-[#ffffff] p-6 sm:p-5">
          <div className="w-full">
            <div className="ml-1 flex items-center justify-between gap-5 md:ml-0">
              <Heading
                size="desktop_label_bold"
                as="h1"
                className="self-end text-[20px] font-semibold text-[#000000]"
              >
                Neue Landingpage freigeben
              </Heading>
              <img
                src="/images/img_cross_small_1.svg"
                alt="Close Icon"
                className="h-[32px] cursor-pointer"
                onClick={() => onOpenChange(false)}
              />
            </div>
            <div className="ml-1 mt-6 flex flex-col gap-5 md:ml-0">
              <div className="flex flex-wrap items-center justify-between gap-5 secondary:gap-10 xl:flex-nowrap ">
                <FloatLabel
                  label={"Landingpage auswählen"}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
                  className={" flex-grow"}
                  value={landingPage}
                  onChange={(e) => setLandingPage(e.target.value)}
                  disabled={editingLP}
                >
                  <div className="w-full flex justify-between items-center ">
                    <select
                      value={landingPage}
                      onChange={(e) => setLandingPage(e.target.value)}
                      className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] h-[54px] p-[0px_12.5px] w-full !appearance-none"
                      disabled={editingLP}
                    >
                      <option value=""></option>
                      {landingPages.map((x) => (
                        <option
                          value={x._id}
                          disabled={landingPageThemes
                            .map((b) => b.landingPage?._id)
                            .includes(x._id)}
                        >
                          {x.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src="/images/img_arrowup.svg"
                      alt="Arrow Up"
                      className="h-[24px] w-[20px] object-contain absolute right-3"
                    />
                  </div>
                </FloatLabel>
              </div>
              <div className="flex flex-wrap items-center justify-between gap-5 secondary:gap-10 xl:flex-nowrap ">
                <FloatLabel
                  label={"Thema"}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
                  className={" flex-grow"}
                  value={theme}
                  onChange={(e) => {
                    if (e.value === "create")
                      return setCreateThemeModalOpen(true);
                    setTheme(e);
                  }}
                >
                  <div className="w-full flex justify-between items-center ">
                    <SelectBox
                      shape="round"
                      indicator={<></>}
                      formatOptionLabel={(e) => (
                        <div className="justify-between flex w-full items-center">
                          <div className="flex items-center gap-2 w-full">
                            {typeof e.icon === "string" ? (
                              <img
                                className="w-[16px] h-[16px] object-contain"
                                src={e.icon}
                              />
                            ) : (
                              e.icon
                            )}
                            <span>{e.label || e.name}</span>
                          </div>
                          {e.isCustom && (
                            <MdDelete
                              color="red"
                              className="cursor-pointer"
                              size={18}
                              onClick={async (x) => {
                                x.preventDefault();
                                x.stopPropagation();
                                if (!e.value) return;

                                Modal.confirm({
                                  title: "Bist du sicher?",
                                  content: `Möchtest du das Thema ${
                                    e.label || e.name
                                  } unwiderruflich löschen?`,
                                  closable: true,

                                  onOk: async () => {
                                    await CustomThemeService.deleteCustomTheme(
                                      e.value
                                    );
                                    fetchCustomThemes();
                                    if (theme.value === e.value) setTheme("");
                                  },
                                  footer: (_, { OkBtn, CancelBtn }) => (
                                    <>
                                      <OkBtn />
                                    </>
                                  ),
                                });
                              }}
                            />
                          )}
                        </div>
                      )}
                      name="Advisor Dropdown"
                      placeholder={``}
                      options={[
                        {
                          value: "create",
                          label: (
                            <div className="flex items-center gap-2 py-2">
                              <PlusOutlined />
                              <span>Erstellen</span>
                            </div>
                          ),
                        },
                        ...defaultThemes,
                        ...customThemes,
                      ]}
                      className="gap-3 rounded-[14px] !border-[1.5px] font-semibold  border-[1px] border-[#d8d8d8] outline-none rounded-[15px] w-full !appearance-none"
                      value={theme}
                      onChange={(e) => {
                        if (e.value === "create")
                          return setCreateThemeModalOpen(true);
                        setTheme(e);
                      }}
                    />

                    <img
                      src="/images/img_arrowup.svg"
                      alt="Arrow Up"
                      className="h-[24px] w-[20px] object-contain absolute right-3"
                    />
                  </div>
                </FloatLabel>
              </div>
              {!isEGTBonus && (
                <div className="flex flex-wrap items-center justify-between gap-5 secondary:gap-10 xl:flex-nowrap ">
                  <FloatLabel
                    label={"Linkeignungstext"}
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
                    className={" flex-grow"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  >
                    <div className="w-full flex justify-between items-center ">
                      <textarea
                        rows={4}
                        className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <img
                        src="/images/img_arrowup.svg"
                        alt="Arrow Up"
                        className="h-[24px] w-[20px] object-contain absolute right-3"
                      />
                    </div>
                  </FloatLabel>
                </div>
              )}
            </div>
            <Button
              shape="round"
              className="w-full ml-1 mt-[60px] rounded-[10px] px-[34px] md:ml-0 sm:px-5"
              onClick={handleSubmit}
            >
              {editingLP ? "Jetzt speichern" : "Jetzt freigeben"}
            </Button>
          </div>
        </div>
      </Modal>

      <CreateThemeModal
        open={createThemeModalOpen}
        onOpenChange={setCreateThemeModalOpen}
        onThemeCreate={handleThemeCreate}
      />
    </>
  );
}
