import { Button, Modal, Switch, Input } from "antd";
import { BsLink45Deg, BsTrash3 } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { EditOutlined } from "@ant-design/icons";
import { message } from "antd";
import TextArea from "antd/es/input/TextArea";
import classNames from "classnames";
import moment from "moment/moment";
import React, { useReducer, useRef, useState } from "react";
import { LuPhone } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selectors";
import AdminService from "../../../service/AdminService";
import { PopUpComponent } from "../../ConfirmPopup";
import { ShareIcon } from "../Navitems";
import PartnerSubscription from "./PartnerSubscription";
import MessagePartnerModal from "./sendMessageModal";

const ExpandedCardVersionTwo = ({
  data,
  expanded,
  className,
  props,
  manipulateTop,
  bg,
  getPartners,
  setList,
}) => {
  const [open, setOpen] = useState(false);
  const [SecondDropDown, setSecondDropDown] = useState(false);
  const notzRef = useRef();
  const cardExpandedRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const { user } = useSelector(selectUser);
  const [formDataLoading, setFormDataLoading] = useState({
    notizen: false,
  });
  const [newNote, setNewNote] = useState("");
  const [partnerData, setPartnerData] = useState(data);

  const onChange = (field, value) => {
    setPartnerData((prev) => ({ ...prev, [field]: value }));
  };

  const editOkHandler = async () => {
    setEditLoading(true);
    try {
      const re = await AdminService.updateUserDetails({
        user_id: partnerData._id,
        data: partnerData,
      });
      if (re.status === 200) {
        message.success("Partner Daten geändert");
      }
    } catch (err) {
      message.error("Partner Daten konnten nicht gespeichert werden.");
    } finally {
      setEditModalOpen(false);
      setEditLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setFormDataLoading({ notizen: true });
    setList({
      key: "notes",
      value: [
        ...data?.notes,
        {
          text: newNote,
          timestamp: new Date(),
        },
      ],
    });
    setFormDataLoading({ notizen: false });
    setNewNote("");
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        manipulateTop(cardExpandedRef?.current?.offsetHeight - 100);
      }, 500);
    }
  }, [expanded, cardExpandedRef, notzRef, manipulateTop, data]);

  React.useEffect(() => {
    data = partnerData;
  }, [partnerData]);

  return (
    <div
      className="w-full   absolute  pointer-events-none  transition-all duration-1000  rounded-11xl "
      {...props}
      onClick={() => {
        props.onClick(() => {
          setSecondDropDown(false);
        });
      }}
    >
      <MessagePartnerModal
        open={open}
        close={() => setOpen(false)}
        Partner_id={data?._id}
      />
      <Modal
        title="Neue Notiz"
        open={isModalOpen}
        onOk={handleOk}
        okText="Speichern"
        okButtonProps={{ loading: formDataLoading.notizen }}
        onCancel={handleCancel}
      >
        <TextArea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
      </Modal>
      <Modal
        title="Partner bearbeiten"
        open={editModalOpen}
        onOk={editOkHandler}
        okButtonProps={{ loading: editLoading }}
        okText="Speichern"
        onCancel={() => setEditModalOpen(false)}
      >
        <div className="flex gap-2 justify-evenly">
          <div className="flex flex-col justify-between flex-wrap gap-5">
            <div>
              <p>Vorname</p>
              <Input
                value={partnerData?.firstname}
                onChange={(e) => onChange("firstname", e.target.value)}
              />
            </div>
            <div>
              <p>Nachname</p>
              <Input
                value={partnerData?.lastname}
                onChange={(e) => onChange("lastname", e.target.value)}
              />
            </div>

            <div>
              <p>Firmenname</p>
              <Input
                value={partnerData?.companyName}
                onChange={(e) => onChange("companyName", e.target.value)}
              />
            </div>
            <div>
              <p>Telefon</p>
              <Input
                value={partnerData?.phone}
                onChange={(e) => onChange("phone", e.target.value)}
              />
            </div>
            <div>
              <p>Geburtsdatum</p>
              <Input
                value={
                  partnerData?.dateOfBirth
                    ? moment(partnerData?.dateOfBirth?.split("T")[0]).format(
                        "D.M.YYYY"
                      )
                    : ""
                }
                onChange={(e) => onChange("dateOfBirth", e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between flex-wrap gap-5">
            <div>
              <p>VAT</p>
              <Input
                value={partnerData?.VAT}
                onChange={(e) => onChange("VAT", e.target.value)}
              />
            </div>
            <div>
              <p>Straße</p>
              <Input
                value={partnerData?.street}
                onChange={(e) => onChange("street", e.target.value)}
              />
            </div>
            <div>
              <p>Hausnummer</p>
              <Input
                value={partnerData?.streetNr}
                onChange={(e) => onChange("streetNr", e.target.value)}
              />
            </div>
            <div>
              <p>Ort</p>
              <Input
                value={partnerData?.city}
                onChange={(e) => onChange("city", e.target.value)}
              />
            </div>
            <div>
              <p>PLZ</p>
              <Input
                value={partnerData?.postCode}
                onChange={(e) => onChange("postCode", e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div
        className={` h-full w-full relative top-[11%]  rounded-[30px]        ${className}`}
      >
        <svg
          className="absolute right-0 w-[555px] md:block hidden top-[-100px] z-[17] pointer-events-none"
          height="102"
          viewBox="0 0 555 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M553.5 100.5H155.769C158.295 98.7366 160.335 96.2715 161.582 93.3094L187.705 31.2724C195.302 13.2314 212.97 1.5 232.545 1.5H525C540.74 1.5 553.5 14.2599 553.5 30V100.5Z"
            fill={bg}
            stroke="#D8D8D8"
            strokeWidth="3"
          />
        </svg>
        <svg
          width="265"
          className="absolute right-[1px]  md:hidden  top-[-79px] z-[17] pointer-events-none"
          height="81"
          viewBox="0 0 265 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-85.2032 103C-85.2032 91.7977 -76.2489 82.7162 -65.2032 82.7162H-25.0156H-9.932C2.03887 82.7162 12.7016 75.0411 16.6527 63.5809L27.8826 31.0079C34.3272 12.3154 51.719 -0.203247 71.2445 -0.203247H244.797C255.843 -0.203247 264.797 8.87828 264.797 20.0805V71.2968C264.797 82.7162 264.797 81.7968 244.797 81.7968H-65.2032C-76.2489 81.7968 -85.2032 72.7152 -85.2032 61.5129V103Z"
            fill={bg}
            stroke="#D8D8D8"
            strokeWidth="3"
          />
        </svg>
        <div
          className="absolute md:right-0 right-[-100px] top-[-80px]  md:top-[-100px] w-[386px] pointer-events-auto h-[100px] z-[17]"
          onClick={() => props.onClick()}
        >
          <div className="text-center h-full flex  items-center ps-[4rem]">
            {" "}
            <h1
              className={classNames(
                `leading-[38px] md:w-[300px] w-[200px] whitespace-normal hyphens-auto  text-white font-medium `,
                {
                  "text-xl  md:text-[28px]": expanded,
                  "text-base md:text-[20px]": !expanded,
                }
              )}
              style={
                bg[data.status]?.[0] === "#FFE465" ? { color: "black" } : {}
              }
            >
              {data?.firstname}
              {"  "} {data?.lastname}{" "}
              <span className="text-sm">
                {data.subscription?.tier &&
                  data.subscription?.paid &&
                  `(${data.subscription?.tier})`}
              </span>
            </h1>
          </div>
        </div>
        <div
          className={`z-50 relative p-6   rounded-[30px_0px_30px_30px] border-editor border-[2px] border-[#D8D8D8] pointer-events-auto antialiased `}
          style={{
            background: bg,
          }}
        >
          <div className="specialeditor" style={{ color: bg }}>
            <svg
              width="52"
              height="65"
              viewBox="0 0 52 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M270.5 6V1681C270.5 1691.22 262.217 1699.5 252 1699.5H-58C-68.2173 1699.5 -76.5 1691.22 -76.5 1681V82.6154C-76.5 72.3981 -68.2173 64.1154 -58 64.1154H-17.8124H-1.61235C10.3614 64.1154 21.0754 56.6776 25.2613 45.4593L35.8546 17.0688C42.4889 -0.711495 59.47 -12.5 78.4476 -12.5H252C262.217 -12.5 270.5 -4.21727 270.5 6Z"
                fill="currentColor"
                stroke="#D8D8D8"
                stroke-width="2"
              />
            </svg>
          </div>
          <div className="desktopspecialeditor">
            <svg
              width="76"
              height="81"
              viewBox="0 0 76 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M423.5 16V413C423.5 428.74 410.74 441.5 395 441.5H-655C-670.74 441.5 -683.5 428.74 -683.5 413V107.943C-683.5 92.2026 -670.74 79.4427 -655 79.4427H-130H-9.52728H17.07C23.2811 79.4427 28.9303 75.8464 31.559 70.2191L56.5106 16.8054C64.8627 -1.07384 82.8116 -12.5 102.545 -12.5H395C410.74 -12.5 423.5 0.259888 423.5 16Z"
                fill={bg}
                stroke="#D8D8D8"
                stroke-width="2"
              />
            </svg>
          </div>
          <div className="super-line" style={{ background: bg }}></div>

          {expanded ? (
            <div className="pb-[280px] min-h-min" ref={cardExpandedRef}>
              <div className="white-text-div-folder flex flex-col gap-5 bg-white p-2 rounded-[15px] ">
                <div className=" flex justify-between flex-wrap gap-5">
                  <div>
                    <p>Name</p>
                    <h5>{`${partnerData?.firstname ?? ""} ${
                      partnerData?.lastname ?? ""
                    } `}</h5>
                  </div>
                  <div>
                    <p>Firmenname</p>
                    <h5>{`${partnerData?.companyName ?? ""}
                     `}</h5>
                  </div>
                  <div>
                    <p>Telefon</p>
                    <h5>
                      <a href={`tel:${partnerData?.phone}`}>{data?.phone}</a>
                    </h5>
                  </div>
                  <div>
                    <p>E-Mail</p>
                    <h5>
                      <a href={`mailto:${partnerData?.email ?? ""}`}>
                        {partnerData?.email}{" "}
                      </a>
                    </h5>
                  </div>
                  <div>
                    <p>Geburtsdatum</p>
                    <h5>
                      {partnerData?.dateOfBirth &&
                        moment(
                          partnerData?.dateOfBirth?.split?.("T")[0]
                        ).format("D.M.YYYY")}
                    </h5>
                  </div>
                </div>
                <div className=" flex justify-between flex-wrap gap-3">
                  <div>
                    <p>VAT</p>
                    <h5>{`${partnerData?.VAT ?? ""}`}</h5>
                  </div>
                  <div>
                    <p>Straße</p>
                    <h5>{partnerData?.street}</h5>
                  </div>
                  <div>
                    <p>Hausnummer</p>
                    <h5>{partnerData?.streetNr} </h5>
                  </div>
                  <div>
                    <p>Ort</p>
                    <h5>
                      {/* 14. Aug., 2023{" "} */}
                      {partnerData?.city}
                    </h5>
                  </div>
                  <div>
                    <p>PLZ</p>
                    <h5>{partnerData?.postCode}</h5>
                  </div>
                  <div>
                    <p>Registriert am </p>
                    <h5>
                      {/* 14. Aug., 2023{" "} */}
                      {moment(partnerData?.createdAt)?.format("D.M.YYYY")}
                    </h5>
                  </div>
                  <div>
                    <p>Empfehlungen</p>
                    <h5>{partnerData?.numberOfEmpfehlungen}</h5>
                  </div>
                  <div>
                    <p>Promoter</p>
                    <h5>{partnerData?.numberOfPromoters}</h5>
                  </div>
                </div>
                <div className="flex justify-between flex-wrap gap-3">
                  <div className="blueswitch">
                    <p>Zugang Erlaubt</p>
                    <Switch
                      checked={data?.hasAccess}
                      onChange={(e) =>
                        setList({
                          key: "hasAccess",
                          value: e,
                        })
                      }
                    />
                  </div>
                  <div className="blueswitch">
                    <p>Admin</p>
                    <Switch
                      checked={data?.accessLevels?.adminNotifications?.read}
                      onChange={(e) =>
                        setList({
                          key: "accessLevels",
                          value: {
                            ...data?.accessLevels,
                            adminNotifications: {
                              read: e,
                            },
                          },
                        })
                      }
                    />
                  </div>

                  <div>
                    <p>Letzter Klick </p>
                    <h5>
                      {" "}
                      {data?.lastClick
                        ? moment(data?.lastClick)?.format("D.M.YYYY")
                        : "keine Klicks"}
                    </h5>
                  </div>
                  <div>
                    <p>Letzter Login </p>
                    <h5>
                      {data?.lastActivity
                        ? moment(data?.lastActivity)?.format("D.M.YYYY")
                        : ""}
                    </h5>
                  </div>
                  <div>
                    <p>Letzter Promoter </p>
                    <h5>
                      {" "}
                      {data?.lastPromoter?.createdAt
                        ? moment(data?.lastPromoter?.createdAt)?.format(
                            "D.M.YYYY"
                          )
                        : ""}
                    </h5>
                  </div>
                  <div className="blueswitch">
                    <p>Kostenloser Zugang </p>
                    <Switch
                      checked={data?.canAccessSystemForFree}
                      onChange={(e) =>
                        setList({
                          key: "canAccessSystemForFree",
                          value: e,
                        })
                      }
                    />
                  </div>
                  <div className="blueswitch">
                    <p>Testphase Newsletter </p>
                    <Switch
                      checked={data?.testPhaseNewsletter}
                      onChange={(e) =>
                        setList({
                          key: "testPhaseNewsletter",
                          value: e,
                        })
                      }
                    />
                  </div>
                </div>

                <PartnerSubscription
                  subscriptionUser={data?.subscription}
                  userid={data._id}
                />

                <div className="flex flex-wrap items-center gap-3 my-10">
                  <Button
                    type="primary"
                    onClick={() => setOpen(true)}
                    className="bg-[#001D47] rounded-[10px] h-[32px] flex items-center gap-3 !text-[#ffffff]"
                  >
                    <ShareIcon /> <h1>Nachricht schicken</h1>
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => setEditModalOpen(true)}
                    className="bg-[#001D47] rounded-[10px] h-[32px] flex items-center gap-3 !text-[#ffffff]"
                  >
                    <EditOutlined /> <h1>Partner bearbeiten</h1>
                  </Button>
                  <Button
                    onClick={() => {
                      AdminService?.manuallySendResetPasswordLink(data?._id);
                    }}
                    type="primary"
                    className="bg-[#001D47]  rounded-[10px] sm:h-[32px] h-fit flex items-center gap-3 text-white"
                  >
                    <BsLink45Deg fontSize={22} />{" "}
                    <h1 className="whitespace-normal h-fit">
                      Link zum Passwort Zurücksetzten schicken
                    </h1>
                  </Button>
                  <Button
                    onClick={() =>
                      PopUpComponent({
                        onConfirm: async () => {
                          try {
                            await AdminService?.deleteUserAccount(data?._id);
                            getPartners();
                          } catch (error) {
                            console.log(error);
                          }
                        },
                        heading: "Partner Löschen",
                        subheading: `Bist du dir sicher, dass du den Partner ${data?.firstname} ${data?.lastname} löschen möchtest?`,
                        confirmButtonText: "Löschen",
                        cancelButtonText: "Abbrechen",
                      })
                    }
                    className="bg-redattendtion h-[32px] rounded-[10px] flex items-center gap-3 text-white"
                  >
                    <BsTrash3 fontSize={20} /> <h1>Partner Löschen</h1>
                  </Button>
                </div>
              </div>

              <div className="flex items-center gap-3 text-white mt-10 transition-all ">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setSecondDropDown(!SecondDropDown);
                    if (SecondDropDown) {
                      manipulateTop(
                        (prev) => prev - notzRef.current.offsetHeight
                      );
                    } else {
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + notzRef.current.offsetHeight
                        );
                      }, 500);
                    }
                  }}
                >
                  Notizen
                </p>
                <IoIosArrowDown
                  className="transition-all cursor-pointer"
                  onClick={() => {
                    setSecondDropDown(!SecondDropDown);
                    if (SecondDropDown) {
                      manipulateTop(
                        (prev) => prev - (notzRef.current.offsetHeight + 30)
                      );
                    } else {
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + (notzRef.current.offsetHeight + 30)
                        );
                      }, 500);
                    }
                  }}
                  style={{
                    rotate: !SecondDropDown ? "180deg" : "0deg",
                  }}
                />
              </div>
              <div
                className=" !transition-all"
                ref={notzRef}
                style={{
                  display: SecondDropDown ? "block" : "none",
                }}
              >
                <Button
                  type="primary"
                  className="bg-[#001D47] my-3"
                  onClick={() => setIsModalOpen(true)}
                >
                  Neue Notiz
                </Button>

                <div className="flex flex-col gap-3   pt-3">
                  {data?.notes?.length > 0 ? (
                    [...data.notes]
                      .sort((a, b) => {
                        const date1 = new Date(a?.timestamp);
                        const date2 = new Date(b?.timestamp);

                        return date2 - date1;
                      })
                      ?.map((e, i) => (
                        <div
                          key={i}
                          className="  bg-white py-[1rem] w-[97%] px-[2rem] rounded-[15px] relative"
                        >
                          <div>
                            <p className=" text-[12px] text-[#868686]">
                              {moment(e?.timestamp).format("D.M.YYYY HH:mm")}
                            </p>
                            <p>{e?.text}</p>
                            <div className="bg-white border border-[#d8d8d8]  p-2 absolute top-[-10px] right-[-10px] rounded-[8px] w-fit">
                              <RxCross1
                                className="cursor-pointer"
                                onClick={() => {
                                  PopUpComponent({
                                    onConfirm: () => {
                                      setList({
                                        key: "notes",
                                        value: [...data.notes].filter(
                                          (fe) => fe._id !== e._id
                                        ),
                                      });
                                    },
                                    heading:
                                      "Bist Du dir sicher, dass du diese Notiz löschen möchtest?",
                                    confirmButtonText: "Löschen",
                                    cancelButtonText: "Abbrechen",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="text-white">Noch keine Notizen</div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="white-text-div-folder h-[100px] relative"
              style={{
                zIndex: (props.style.zIndex + 1) * 1,
              }}
            >
              <div className="md:flex hidden items-center gap-3">
                <p className="!text-white ">Telefon</p>
                <h5 className="text-white">
                  <a href={`tel:${data.phone}`}>{data.phone}</a>
                </h5>
              </div>

              <a
                href={`tel:${data.phone}`}
                className="text-white md:hidden text-primary"
              >
                <LuPhone />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ExpandedCardVersionTwo };
