import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LandingPageService from "../../service/LandingPageService";
import PublicService from "../../service/PublicService";
import FormComponent from "./FormComponent";
import QuestionareComponent from "./QuestionComponent";
import LandingPageClosingFormular from "../_NEW/LandingPageClosingFormular/LandingPageClosingFormular";
import constructWhatsappLink from "../../utils/whatsappLink";
import { replaceText } from "../../utils/replaceText";
import { useMessageContext } from "../../context/messageContext";
import { phoneRegex } from "../../utils/validation";

const Questionare = ({ data, styles }) => {
  const { id, promoter_id, clickId } = useParams();
  const [promoter, setPromoter] = useState();
  const [user, setUser] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [details, setDetails] = useState(null);
  const [answerDetails, setAnswerDetails] = useState([]);
  const [phone, setPhone] = useState("");
  const [step, setStep] = useState(0);
  const [endText, setEndText] = useState("");
  const [wrongAnswers, setWrongAnswers] = useState({
    count: 0,
    showFormWithRedText: false,
  });
  const [disableButtons, setDisableButtons] = useState(false);
  const { showMessage, clearMessage } = useMessageContext();
  const location = useLocation();
  const { affiliate } = location.state || {};
  useEffect(() => {
    // console.log(
    //   "recall",
    //   firstName,
    //   phone,
    //   promoter_id,
    //   details?.user_id,
    //   answerDetails,
    //   clickId,
    //   id
    // );
    const fetchData = async () => {
      let promoter = {
        data: {
          firstname: "Max",
          lastname: "Mustermann",
        },
      };
      const { data: landingPageData } = await LandingPageService.detail(id);
      const { data: userData } = await PublicService.getUserName(
        landingPageData.user_id
      );
      if (promoter_id !== "viewmode")
        promoter = await PublicService.getPromoterInitials(promoter_id);

      setDetails({
        ...landingPageData,
        transformed_whatsappText: replaceText(landingPageData.whatsappText, {
          "{Promoter Vorname}": promoter.data.firstname,
          "{Promoter Nachname}": promoter.data.lastname,
        }),
      });

      setPromoter(promoter);
      setUser(userData);
    };

    fetchData();
  }, []);
  const onSubmit = async (submitType) => {
    if (firstName === "" || phone === "") {
      return showMessage("error", "Bitte alle Felder ausfüllen");
    }

    if (!phoneRegex.test(phone)) {
      return showMessage("error", "Bitte gültige Nummer eingeben!");
    }

    if (!acceptPrivacy) {
      return showMessage(
        "error",
        "Du musst der Datenschutzerklärung von EinGuterTipp zustimmen"
      );
    }

    setDisableButtons(true);
    showMessage("loading", "Anfrage wird bearbeitet...", 0, "popup-key");

    let payload = null;

    // Falls wir nicht im Viewmode sind:
    if (promoter_id !== "viewmode") {
      if (affiliate) {
        // Affiliate-Fall: Es gibt keinen promoter_id und keine clickId.
        payload = {
          type: submitType,
          firstname: firstName,
          phone: phone,
          affiliate_user_id: promoter_id,
          answersToQuestions: answerDetails,
          landingpage_id: id,
        };
      } else {
        // Normaler Fall: Empfehlung mit promoter_id und clickId
        payload = {
          type: submitType,
          firstname: firstName,
          phone: phone,
          promoter_id: promoter_id,
          user_id: details?.user_id,
          answersToQuestions: answerDetails,
          landingpageaufruf_id: clickId,
          landingpage_id: id,
        };
      }
      await PublicService.createEmpfehlung(payload);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    clearMessage("popup-key");

    // Endformular anzeigen
    setSubmitted(true);
    if (submitType === "whatsapp" || submitType === "chooseDate") {
      console.log(submitType);
      setEndText(
        "Ich freue mich auf Deinen Kontakt. Du wurdest bereits weitergeleitet."
      );
    } else {
      setEndText(details?.vielenDankfurInteresse);
    }

    // Weiterleitung
    if (submitType === "whatsapp") {
      const text = `Hi ${user.firstname.trim()}. Ich bin ${firstName.trim()} und komme auf Empfehlung von ${promoter.data.firstname.trim()} ${promoter.data.lastname.trim()}. Ich suche Unterstützung zu folgendem Thema:`;
      window.location.href = constructWhatsappLink(
        details?.whatsappNumber,
        text
      );
    } else if (submitType === "chooseDate") {
      window.location.href = details?.calendlyLink;
    }
  };

  const endOfQuestionare = step === data?.length || data?.length === 0;

  return (
    <div style={{ fontFamily: styles.font }} className="w-full overflow-y-auto">
      {data.map((e, i) => (
        <QuestionareComponent
          key={i}
          setAnswerDetails={setAnswerDetails}
          answers={e.answers}
          question={e.question}
          totalRejectionAnswers={e.totalRejectionAnswers}
          setWrongAnswers={setWrongAnswers}
          bg={e.bg}
          id={id}
          index={i}
          modelBg={styles.modelBg}
          total={data.length}
          step={() => setStep((prev) => prev + 1)}
          visible={i === step}
          rounded={styles.rounded}
        />
      ))}

      <LandingPageClosingFormular
        disableButtons={disableButtons}
        privacy={acceptPrivacy}
        setPrivacy={setAcceptPrivacy}
        positive={!wrongAnswers.showFormWithRedText}
        visible={endOfQuestionare && !submitted}
        landingPageData={details}
        name={firstName}
        setName={setFirstName}
        number={phone}
        setNumber={setPhone}
        onChoseDate={() => onSubmit("chooseDate")}
        onWhatsappButton={() => onSubmit("whatsapp")}
        onRecall={() => onSubmit("recall")}
        noQuestions={data.length === 0}
      />

      <FormComponent
        bg={details?.background_image}
        rounded={styles.rounded}
        visible={endOfQuestionare && submitted}
      >
        <div className="md:text-9xl text-xl font-semibold text-center text-[#40C945]">
          {endText}
        </div>
      </FormComponent>
    </div>
  );
};

export default Questionare;
