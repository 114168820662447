import React, { useState, useEffect } from "react";
import PublicService from "../../service/PublicService";

const Conditions = () => {
  const [conditions, setConditions] = useState();
  const getConditions = async () => {
    try {
      const { data } = await PublicService.getCMS();
      setConditions(data.CMS.conditions);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getConditions();
  }, []);
  return (
    <div className="p-10">
      <h1 className="text-xl font-bold text-center formattierterRechtsText">
        Konditionen
      </h1>
      <div
        className="my-10"
        dangerouslySetInnerHTML={{ __html: conditions }}
      />
    </div>
  );
};

export default Conditions;
