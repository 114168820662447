import React from "react";
import Draggable from "react-draggable";
import GradientPicker from "react-best-gradient-color-picker";

const GradientPickerModal = ({
  visible,
  value,
  onChange,
  onClose,
  title = "Gradient Picker",
}) => {
  if (!visible) return null;

  return (
    <Draggable handle=".modal-header">
      <div
        className="gradient-picker-modal"
        style={{
          position: "absolute",
          bottom: "-50%",
          left: "30%",
          zIndex: 1000,
          backgroundColor: "#fff",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          width: "280px",
        }}
      >
        {/* Header */}
        <div
          className="modal-header"
          style={{
            cursor: "move",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>{title}</span>
          <span
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              padding: "1px 6px",
              borderRadius: "50%",
              cursor: "pointer",
              fontSize: "12px",
            }}
            onClick={onClose}
          >
            ×
          </span>
        </div>

        {/* Gradient Picker */}
        <div
          style={{
            transform: "scale(0.9)",
            transformOrigin: "top left",
            marginBottom: "8px",
          }}
        >
          <GradientPicker value={value} onChange={onChange} />
        </div>

        {/* Action Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <button
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              padding: "6px 12px",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
              fontSize: "12px",
            }}
            onClick={onClose}
          >
            Anwenden
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default GradientPickerModal;
