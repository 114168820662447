import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { replaceAndMark } from "../../../../../utils/replaceAndMarkText";
import { replaceText } from "../../../../../utils/replaceText";
import { imageBackground } from "../../../../../utils/setImageBackground";
import { sendEventUser } from "../../../../../utils/analytics";
import Hero from "../hero/Hero";
import WhatsappButton from "../../shared/WhatsappButton";
import LocalReviews from "../localReviews/LocalReviews";
import Video from "../../shared/Video";
import PortraitAndText from "../portraitAndText/PortraitAndText";
import TypeButton from "../../shared/TypeButton";
import Footer from "../footer/Footer";

const FinanceView = ({
  landingPageData,
  clickid,
  promoter,
  localReviews,
  type,
  promoter_id,
  landingPageId,
  user,
  affiliate,
}) => {
  const navigate = useNavigate();

  // Hilfsfunktionen
  const withBackground = (set) => {
    return set ? "bg-[#000000] p-1 rounded-sm bg-opacity-50" : "";
  };

  const isEmptyHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText.trim() === "";
  };

  // Daten aus landingPageData
  const {
    activateLocalReviews,
    whatsappButtonTextOne,
    whatsappButtonTextTwo,
    persentationVideo,
    presentationVideoDuration,
    thumbnail,
    questionaireIntroductionText,
    questionaireButtonText,
    whatsappText,
    whatsappNumber,
    activateWhatsApp,
    logo,
    localReviewsHeader,
    introVideoHeader,
    accompanyingTextOne,
    accompanyingTextTwo,
    accompanyingTextThree,
    introductoryText,
    activateBackgroundAccompanyingTextOne,
    activateBackgroundAccompanyingTextTwo,
    activateBackgroundAccompanyingTextThree,
    activateBackgroundVideoText,
    primary_image,
    activate_wa1,
    activate_wa2,
    whatsAppButtonColor,
    typeButtonColor,
  } = landingPageData;

  // Textersetzung
  [
    "introductoryText",
    "accompanyingTextOne",
    "accompanyingTextTwo",
    "accompanyingTextThree",
    "introVideoHeader",
    "questionaireIntroductionText",
  ].forEach((key) => {
    let temp = replaceAndMark(
      landingPageData[key],
      {
        "{Promoter Vorname}": ` ${promoter.firstname} `,
        "{Promoter Nachname}": ` ${promoter.lastname} `,
      },
      "#00DEF7"
    );
    landingPageData[`transformed_${key}`] = temp;
  });

  const promoterReplacements = {
    "{Promoter Vorname}": ` ${promoter.firstname} `,
    "{Promoter Nachname}": ` ${promoter.lastname} `,
  };

  const textMappings = {
    transformed_whatsappText: whatsappText,
    transformed_introVideoHeader: introVideoHeader,
    transformed_questionaireIntroductionText: questionaireIntroductionText,
    transformed_accompanyingTextOne: accompanyingTextOne,
    transformed_accompanyingTextTwo: accompanyingTextTwo,
    transformed_accompanyingTextThree: accompanyingTextThree,
    transformed_introductoryText: introductoryText,
  };

  Object.keys(textMappings).forEach((key) => {
    landingPageData[key] = replaceText(textMappings[key], promoterReplacements);
  });

  landingPageData.transformed_introVideoHeader = replaceText(introVideoHeader, {
    ...promoterReplacements,
    "{Videolaenge}": Math.round(presentationVideoDuration),
  });

  // shouldHideTextBlockTwo bestimmen
  const shouldHideTextBlockTwo = isEmptyHtml(
    landingPageData.transformed_accompanyingTextTwo
  );

  // Google Analytics PageView
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendEventUser({
        category: "page",
        action: "page_viewLP",
        label: "lppageview",
        value: 1,
        landingPageId: landingPageId,
      });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [landingPageId]);

  // Google Analytics Handler
  const googleAnalyticsHandler = (label) => {
    sendEventUser({
      category: "button",
      action: `click_${label}`,
      label: label,
      value: 1,
      landingPageId: landingPageId,
    });
  };
  return (
    <div className="w-full">
      <Hero landingPageData={landingPageData} type={type} promoter={promoter} />

      {/* Der Hintergrund für finance ist laut Original: bei property => bg-[#F1F3F6], sonst bg-white */}
      <div className="bg-white">
        <div className="p-7 secondary:hidden">
          <div
            className={`bg-white rounded-sm text-xl secondary:mb-14 secondary:mt-14 p-[12px] text-black flex w-full flex-col items-center justify-center secondary:hidden whitespace-pre-line ${withBackground(
              landingPageData.transformed_accompanyingTextOne != "" &&
                activateBackgroundAccompanyingTextOne
            )}`}
          >
            <div
              className="w-max"
              dangerouslySetInnerHTML={{
                __html: landingPageData.transformed_accompanyingTextOne,
              }}
            ></div>
          </div>
        </div>

        <LocalReviews
          localReviews={localReviews}
          activateLocalReviews={activateLocalReviews}
          localReviewsHeader={localReviewsHeader}
          type={type}
          logo={logo}
        />

        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          className="hidden pt-14"
          type={type}
          heading={landingPageData.transformed_introVideoHeader}
          landingPageId={landingPageId}
          videoTextBG={activateBackgroundVideoText}
        />

        {/* Finance-spezifischer Block */}
        <div className="flex-col gap-12 w-full items-center p-16 secondary:flex hidden">
          <div className="flex secondary:flex-row secondary:gap-4 secondary:justify-around w-[100%] secondary:w-[90%]">
            <div className="hidden whitespace-pre-line text-black secondary:block break-words mx-auto xs:w-[500px] primary:w-[600px] secondary:w-[65%]">
              <div
                dangerouslySetInnerHTML={{
                  __html: landingPageData.transformed_accompanyingTextTwo,
                }}
                className={`whitespace-pre-line -mt-[0.5rem] ${withBackground(
                  landingPageData.transformed_accompanyingTextTwo &&
                    activateBackgroundAccompanyingTextTwo
                )} ${
                  !activateBackgroundAccompanyingTextTwo && "text-[#000000]"
                }`}
              ></div>
            </div>

            <div
              className={`w-[40%] aspect-[4/3] ${activateBackgroundAccompanyingTextTwo} -mt-2 rounded-xl hidden secondary:block !bg-top`}
              style={primary_image ? { ...imageBackground(primary_image) } : {}}
            ></div>
          </div>
          {activate_wa2 && (
            <WhatsappButton
              activateWhatsApp={activateWhatsApp}
              number={whatsappNumber}
              whatsappText={landingPageData.transformed_whatsappText}
              whatsAppButtonColor={whatsAppButtonColor}
              text={whatsappButtonTextTwo}
              className="mx-4 py-14 secondary:block hidden"
              type={type}
              sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
            />
          )}

          {landingPageData.transformed_accompanyingTextThree !==
            "<p><br></p>" && (
            <div
              dangerouslySetInnerHTML={{
                __html: landingPageData.transformed_accompanyingTextThree,
              }}
              className={`whitespace-pre-line w-[100%] text-[#000000] p-4 rounded-sm bg-[#fafafa] ${withBackground(
                landingPageData.transformed_accompanyingTextThree &&
                  activateBackgroundAccompanyingTextThree
              )}`}
            ></div>
          )}
        </div>

        {/* Whatsapp Button (für finance auf mobile) */}
        {activate_wa2 && (
          <WhatsappButton
            activateWhatsApp={activateWhatsApp}
            number={whatsappNumber}
            whatsappText={landingPageData.transformed_whatsappText}
            text={whatsappButtonTextTwo}
            className="mx-4 secondary:hidden block"
            type={type}
            whatsAppButtonColor={whatsAppButtonColor}
            sendGoogleAnalytics={() => googleAnalyticsHandler("wa2")}
          />
        )}

        <PortraitAndText
          landingPageData={landingPageData}
          className="secondary:hidden pt-8 flex flex-col gap-10 items-center"
        />

        <img
          src="/images/icons-8-doppelt-runter-21-schwarz.png"
          alt=""
          className="animate-bounce secondary:w-[80px] sm:w-[50px] w-[32px] m-auto mt-8"
        />

        <div
          className="text-center secondary:text-none secondary:mt-12 mt-4 p-7 primary:px-40 overflow-anywhere"
          dangerouslySetInnerHTML={{
            __html: landingPageData.transformed_questionaireIntroductionText,
          }}
        ></div>

        <TypeButton
          className="mx-4 mt-4 mb-14"
          type={type}
          text={questionaireButtonText}
          typeButtonColor={typeButtonColor}
          onClick={() => {
            try {
              googleAnalyticsHandler("qf");
            } catch (error) {
              console.error("Analytics error (Vercel ENV):", error);
            }
            navigate(
              `/questionare/${type}/${landingPageId}/${promoter_id}/${clickid}`,
              { state: { affiliate } }
            );
          }}
        />

        <Footer
          user={user}
          ladingPageId={landingPageId}
          landingPageData={landingPageData}
          promoter_id={promoter_id}
          type={type}
        />
      </div>
    </div>
  );
};

export default FinanceView;
