import { Button } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CiImport } from "react-icons/ci";
import { FaCircle } from "react-icons/fa";
import { HiOutlineFolder } from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { exportToExcel } from "../../../utils/ExportAsExcel";
import { CardObj } from "./CardObj";
import { ExpandedCardVersionTwo as ExpandedCard } from "./Cards";
import { Filter, Heart, MiniShape, New, RainBowCicle } from "./Svgs";
import AdminService from "../../../service/AdminService";

export const customSortOrder = [
  "neu",
  "laufender-vorgang",
  "terminiert",
  "kunde",
  "kein-interesse",
  "neuer-kunde-der-promoter-wurde",
];

const Index = () => {
  const [stats, setStats] = useState({
    empfehlungen: "0",
    neueEmpfehlungen: "0",
    offeneVorgange: "0",
  });

  const [list, setList] = useState([]);
  const [oldList, setOldList] = useState();
  const getLists = async () => {
    try {
      const { data } = await AdminService.listInteressenten();
      setList(data);
      setOldList(data); // Always update oldList when fetching new data
    } catch (error) {
      console.error("Error fetching list:", error);
    }
  };

  useEffect(() => {
    getLists();
  }, []);
  const [expanded, setexpanded] = useState(null);
  const [highlightedCard, setHighlightedCard] = useState("");

  const [filter, setFilter] = useState(false);
  const [topValue, setTopValue] = useState(400);
  const [color, setColor] = useState({
    kunde: false,
    "laufender-vorgang": false,
    neu: false,
    "kein-interesse": false,
    terminiert: false,
    "neuer-kunde-der-promoter-wurde": false,
  });
  const query = window.location.href;
  useEffect(() => {
    if (query.split("?")[1]) {
      const queryRep = query.split("?")[1].split("=");
      if (queryRep[1] === "kunde") {
        setFilter(true);
        setColor({
          kunde: true,
          "laufender-vorgang": false,
          neu: false,
          "kein-interesse": false,
          terminiert: false,
          "neuer-kunde-der-promoter-wurde": false,
        });
      } else if (queryRep[0] === "empfehlungen") {
        setHighlightedCard(queryRep[1]);
        setexpanded(0);
      }
    }
  }, [query, list]);

  // Sort and Filter Logic Empfehlungen Card
  useEffect(() => {
    const filteredAndSortedData = () => {
      const filteredData = oldList.filter((e) => {
        if (
          !color["kunde"] &&
          !color["laufender-vorgang"] &&
          !color["neu"] &&
          !color["kein-interesse"] &&
          !color["terminiert"] &&
          !color["neuer-kunde-der-promoter-wurde"]
        ) {
          return true; // Return all items if no filters are active
        }
        return color[e.status];
      });

      return [...filteredData].sort((a, b) => {
        if (a._id === highlightedCard) return -1;
        if (b._id === highlightedCard) return 1;

        const calculateScore = (status) => {
          const scoreMap = {
            neu: 1,
            "laufender-vorgang": 2,
            terminiert: 3,
            kunde: 4,
            "kein-interesse": 5,
            "neuer-kunde-der-promoter-wurde": 6,
          };
          return scoreMap[status] || 0;
        };

        return calculateScore(a.status) - calculateScore(b.status);
      });
    };

    if (oldList && oldList.length > 0) {
      const sortedData = filteredAndSortedData();
      setList(sortedData);
    }
  }, [highlightedCard, color, oldList]);
  const cardsCollectionRef = React.useRef();

  return (
    <div className="px-2">
      <div className="">
        <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]   rounded-[30px] ">
          <div className=" p-5 mt-5">
            <h1 className="sm:text-[28px] text-xl  font-semibold">
              Alle deine Interessenten
            </h1>
            <div className="flex items-center gap-5 text-[#868686] flex-wrap">
              <div className="flex gap-1">
                <div>
                  <HiOutlineFolder fontSize={20} />
                </div>
                <p>
                  Finde mehr Informationen zu der jeweiligen Person, durchs
                  klicken auf die Karte
                </p>
              </div>
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() =>
                  exportToExcel(
                    [...list].map((e) => {
                      return {
                        Vorname: e.firstname,
                        Nachname: e.lastname,
                        Email: e.email,
                        Telefon: e.phone,
                      };
                    }),
                    "Interessenten"
                  )
                }
              >
                <CiImport fontSize={18} />{" "}
                <p className="select-none">Excel Export</p>
              </div>
            </div>
          </div>
          <div className="tertiary:pt-0 pt-40">
            <div
              className="relative    transition-all"
              ref={cardsCollectionRef}
              style={{
                height: cardsCollectionRef?.current?.scrollHeight,
              }}
            >
              {list.map((_, i) => {
                const props = CardObj(
                  _,
                  getLists,
                  expanded,
                  list,
                  color,
                  setTopValue,
                  setexpanded,
                  topValue,
                  setList,
                  i
                );

                return <ExpandedCard {...props} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
